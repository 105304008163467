import { JsonSchema } from "@emberex/csv-import/lib/types";
import { DISABILITIES, DisabilityAliases } from "../constants/Disability";
import { genderAliases, GENDERS } from "../constants/Gender";
import { GradeAliases, GRADES } from "../constants/Grade";
import { RACES, RaceAliases } from "../constants/Race";

const StudentSchema: JsonSchema = {
  properties: {
    idNumber: {
      type: "string",
      required: true,
      unique: true,
      aliases: [
        "student number",
        "id_number",
        "id",
        "studentNumber",
        "studentid",
      ],
      columnHeader: "ID Number",
    },
    firstName: {
      type: "string",
      required: true,
      aliases: ["first_name", "first"],
      columnHeader: "First Name",
    },
    lastName: {
      type: "string",
      required: true,
      aliases: ["last_name", "last"],
      columnHeader: "Last Name",
    },
    gender: {
      type: "string",
      required: true,
      inputType: "dropdown",
      aliases: ["sex"],
      options: GENDERS.map((gender) => ({
        option: gender,
        aliases: genderAliases[gender],
      })),
      columnHeader: "Gender",
    },
    gradeLevel: {
      type: "string",
      required: true,
      inputType: "dropdown",
      aliases: ["age", "grade level", "grade_level", "grade"],
      options: GRADES.map((grade) => ({
        option: grade,
        aliases: GradeAliases[grade],
      })),
      columnHeader: "Grade Level",
    },
    ell: {
      type: "boolean",
      required: true,
      aliases: ["ell", "english language learner"],
      columnHeader: "Is Student ELL?",
    },
    dob: {
      type: "date",
      required: true,
      aliases: ["birthday"],
      columnHeader: "Date of Birth",
    },
    disabilities: {
      type: "string",
      required: true,
      inputType: "select",
      aliases: ["disabilities"],
      options: [
        ...DISABILITIES.map((disability) => ({
          option: disability,
          aliases: DisabilityAliases[disability],
        })),
      ],
      columnHeader: "Disability categories / 504",
    },
    has504plan: {
      type: "boolean",
      required: true,
      aliases: ["504", "has504plan", "504 plan", "plan504"],
      columnHeader: "Has 504 Plan",
    },
    race: {
      type: "string",
      required: true,
      inputType: "dropdown",
      aliases: ["ethnicity"],
      minCharLength: 5, // to keep requirement even when importing to black/white/asian min char length
      options: [
        ...RACES.map((race) => ({
          option: race,
          aliases: RaceAliases[race],
        })),
        { option: "" }, // so default selection does not fall to "Two or more races"
      ],
      columnHeader: "Race",
    },
    gradeType: {
      type: "string",
      required: true,
      aliases: ["grade_type", "grade type"],
      columnHeader: "Grade Type",
    },
    overallGrade: {
      type: "string",
      required: true,
      aliases: [
        "percentage",
        "letter grade",
        "gpa point",
        "letter",
        "percent",
        "gpa",
      ],
      columnHeader: "Overall Grade",
    },
    mathGrade: {
      type: "string",
      required: false,
      aliases: ["math_grade", "math grade", "math"],
      columnHeader: "Math Grade",
    },
    scienceGrade: {
      type: "string",
      required: false,
      aliases: ["science", "science grade", "science_grade"],
      columnHeader: "Science Grade",
    },
    historyGrade: {
      type: "string",
      required: false,
      aliases: ["history", "history grade", "history_grade"],
      columnHeader: "History Grade",
    },
    spellingGrade: {
      required: false,
      type: "string",
      aliases: ["spelling", "spelling grade", "spelling_grade"],
      columnHeader: "Spelling Grade",
    },
    grammarGrade: {
      type: "string",
      required: false,
      aliases: ["grammar", "grammar grade", "grammar_grade"],
      columnHeader: "Grammar Grade",
    },
    writingGrade: {
      type: "string",
      required: false,
      aliases: ["writing", "writing grade", "writing_grade"],
      columnHeader: "Writing Grade",
    },
  },
};

export default StudentSchema;
