import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import { usePopper } from "react-popper";
import { VirtualElement } from "@popperjs/core";
import { Options as OffsetOptions } from "@popperjs/core/lib/modifiers/offset";
import { BasePlacement, ComputedPlacement } from "@popperjs/core";
import useOutsideClick from "../hooks/useOutsideClick";

const oppositeDirection: { [key in BasePlacement]: BasePlacement } = {
  left: "right",
  right: "left",
  top: "bottom",
  bottom: "top",
};

const homepagePopperOffset: OffsetOptions["offset"] = ({ placement }) => {
  const distance = 60;
  let skidding = 0;
  if (placement.includes("end")) {
    skidding = 40;
  } else if (placement.includes("start")) {
    skidding = -35;
  }
  return [skidding, distance];
};

export type PopoverVariant = "homepage" | "homedashboard" | "dashboard";

export type AnchorElement = Element | Required<VirtualElement> | null;

export interface PopoverBaseProps extends PropsWithChildren {
  /**
   * The element that the popover arrow will point to.
   *
   * This value needs to be initialized with useState rather than useRef:
   *
   * ```
   * const [anchor, setAnchor] = useState<HTMLElement | null>(null);
   * return (
   *     <div ref={setAnchor}>
   *         <PopoverBase anchorElement={anchor} {...props} />
   *     </div>
   * )
   * ```
   */
  anchorElement: AnchorElement;
  onClose: () => void;
  variant: PopoverVariant;
}

export const PopoverBase: FC<PopoverBaseProps> = ({
  children,
  anchorElement,
  onClose,
  variant,
  ...props
}) => {
  // Popper requires useState instead of useRef
  // https://popper.js.org/react-popper/v2/#example
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const popperOptions = useMemo<Parameters<typeof usePopper>[2]>(() => {
    if (variant === "homepage" || variant === "homedashboard") {
      return {
        placement: variant === "homepage" ? "right-start" : "left-start",
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom"],
              rootBoundary: "document",
            },
          },
          {
            name: "arrow",
            options: {
              padding: 40,
              element: arrowElement,
            },
          },
          {
            name: "offset",
            options: {
              offset: homepagePopperOffset,
            },
          },
        ],
      };
    }

    return {
      placement: "left",
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: ["left", "bottom"],
            rootBoundary: "document",
          },
        },
        {
          name: "arrow",
          options: {
            element: arrowElement,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 60],
          },
        },
      ],
    };
  }, [variant, arrowElement]);

  const popper = usePopper(anchorElement, popoverElement, popperOptions);

  const popperPlacement = (popper.state?.attributes.popper?.[
    "data-popper-placement"
  ] ?? "left") as ComputedPlacement;

  const arrowDirection = useMemo(() => {
    // e.g. "left-end" -> "left"
    const popperSide = popperPlacement.replace(/-.*$/, "") as BasePlacement;
    return oppositeDirection[popperSide];
  }, [popperPlacement]);

  // Close if a click is outside both the anchor and the popover
  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const nodeElement = event.target instanceof Node ? event.target : null;
      if (nodeElement && !popoverElement?.contains(nodeElement)) {
        onClose();
      }
    },
    [popoverElement, onClose]
  );

  // In case anchorElement is a VirtualElement, get a real DOM element
  const realAnchorElement = useMemo(() => {
    if (!anchorElement) {
      return null;
    }
    if (anchorElement instanceof Element) {
      return anchorElement;
    }
    return anchorElement.contextElement;
  }, [anchorElement]);

  useOutsideClick({ current: realAnchorElement }, handleOutsideClick);

  return (
    <Root
      ref={setPopoverElement}
      {...props}
      style={popper.styles.popper}
      {...popper.attributes.popper}
    >
      <PopoverContainer variant={variant}>
        <ArrowContainer
          direction={arrowDirection}
          variant={variant}
          ref={setArrowElement}
          style={popper.styles.arrow}
        >
          <PopoverArrow direction={arrowDirection} variant={variant} />
        </ArrowContainer>
        {variant === "dashboard" || variant === "homedashboard" ? (
          <PopoverContent>{children}</PopoverContent>
        ) : (
          children
        )}
      </PopoverContainer>
    </Root>
  );
};

export default styled(PopoverBase)``;

const Root = styled(Row)``;

export const PopoverContainer = styled(Column)<{ variant: PopoverVariant }>`
  color: #fff;
  background-color: #262626;
  font-family: "Muli";
  font-weight: 300;
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 2.5rem;
  transition: all 0.5s ease;

  ${({ variant }) => {
    if (variant === "homepage" || variant === "homedashboard") {
      if (variant === "homepage") {
        return css`
          width: 538px;
          border-radius: 37px;
          padding: 1.75rem 1.25rem 2.0625rem 1.25rem;
          @media only screen and (max-width: 1200px) {
            transform: scale(0.85) translate(-50px, -40px);
          }
          @media only screen and (max-width: 900px) {
            transform: scale(0.7) translate(-120px, -80px);
          }
          @media only screen and (max-width: 750px) {
            transform: scale(0.55) translate(-250px, -150px);
          }
          @media only screen and (max-width: 600px) {
            transform: scale(0.45) translate(-400px, -250px);
          }
        `;
      }
      return css`
        width: 538px;
        border-radius: 37px;
        padding: 1.75rem 1.25rem 2.0625rem 1.25rem;
        @media only screen and (max-width: 1200px) {
          transform: scale(0.85) translateX(50px)
        }
        @media only screen and (max-width: 900px) {
          transform: scale(0.6) translateX(200px) ;
        }
        @media only screen and (max-width: 750px) {
          transform: scale(0.45) translateX(400px);
        }
        @media only screen and (max-width: 600px) {
          transform: scale(0.40) translate(500px, -160px);
      `;
    }
    if (variant === "dashboard") {
      return css`
        width: 295px;
        height: 240px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 1px 2px 12px 4px rgba(128, 128, 128, 0.12);
      `;
    }
  }}
`;

const arrowOffset: { [key in PopoverVariant]: string } = {
  homepage: "-20px",
  homedashboard: "-20px",
  dashboard: "-15px",
};

const ArrowContainer = styled.div<{
  direction: BasePlacement;
  variant: PopoverVariant;
}>`
  ${({ variant, direction }) => {
    return css`
      ${direction}: ${arrowOffset[variant]};
    `;
  }}
`;

const arrowRotation: { [key in BasePlacement]: number } = {
  right: 0,
  bottom: 90,
  left: 180,
  top: 270,
};

export const PopoverArrow = styled.div<{
  direction: BasePlacement;
  variant: PopoverVariant;
}>`
  background-color: #262626;
  ${({ variant, direction }) => {
    if (variant === "homepage" || variant === "homedashboard") {
      return css`
        width: 45px;
        height: 45px;
        border-radius: 6px;
        transform: rotate(${45 + arrowRotation[direction]}deg)
          skew(-20deg, -20deg);
      `;
    }
    if (variant === "dashboard") {
      return css`
        width: 33px;
        height: 33px;
        border-radius: 4px;
        box-shadow: 2px 1px 12px 4px rgba(128, 128, 128, 0.12);
        clip-path: polygon(-25% -25%, 125% -25%, 125% 125%);
        transform: rotate(${45 + arrowRotation[direction]}deg);
      `;
    }
  }}
`;

const PopoverContent = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 1rem 1rem 0;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scrollbar-color: #4d4d4d #000;
  ::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
  }
  ::-webkit-scrollbar-track {
    background-color: #000;
    border-radius: 10px;
  }
`;
