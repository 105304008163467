import { ComponentPropsWithoutRef, FC } from "react";
import styled from "styled-components";

export interface CloseButtonProps extends ComponentPropsWithoutRef<"button"> {}

export const CloseButton: FC<CloseButtonProps> = (props) => {
  return (
    <button {...props}>
      <span>X</span>
    </button>
  );
};

export default styled(CloseButton)`
  cursor: pointer;
  background-color: transparent;
  height: 35px;
  width: 35px;
  border: 1px solid #d3ef58;
  border-radius: 50%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 21px;
    color: #d3ef58;
    font-family: "Muli";
    font-weight: 300;
  }
`;
