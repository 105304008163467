import { FC } from "react";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import IslandLinkCard from "../components/IslandLinkCard";
import { HOME } from "../constants/routes";
import BurgerModalMenu from "../components/BurgerModalMenu";

const Home: FC = (props) => {
  const { pathname } = useLocation();
  const onHomePage = pathname === HOME;
  return (
    <Page {...props}>
      <ScrollRestoration />
      <BurgerModalMenu />
      <Header />
      <Outlet />
      {onHomePage && <Footer />}
    </Page>
  );
};

export default styled(Home)`
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;

  ${Footer} {
    margin: auto 0 66px 0;
  }
  ${IslandLinkCard} {
    border: 1px dashed yellow;
    position: absolute;
    right: 0;
    margin: auto;
  }
`;
