import { FC, useCallback, useState, Fragment } from "react";
import styled from "styled-components";
import Student from "../types/Student";
import StudentPerformance from "../types/StudentPerformance";
import formatName from "../utils/formatName";
import Button from "./Button";
import PopoverBase, { PopoverBaseProps } from "./PopoverBase";

export type { AnchorElement } from "./PopoverBase";

type StudentInfo = Pick<
  Student,
  "gender" | "isEll" | "has504Plan" | "race" | "disability"
>;

const infoLabels: { label: string; key: keyof StudentInfo }[] = [
  { label: "Gender", key: "gender" },
  { label: "ELL", key: "isEll" },
  { label: "504 Plan", key: "has504Plan" },
  { label: "Race", key: "race" },
  { label: "Disability", key: "disability" },
];

type StudentScores = Pick<
  StudentPerformance,
  | "mathGrade"
  | "scienceGrade"
  | "historyGrade"
  | "spellingGrade"
  | "grammarGrade"
  | "writingGrade"
>;

const scoreLabels: { label: string; key: keyof StudentScores }[] = [
  { label: "Math", key: "mathGrade" },
  { label: "Science", key: "scienceGrade" },
  { label: "History", key: "historyGrade" },
  { label: "Spelling", key: "spellingGrade" },
  { label: "Grammar", key: "grammarGrade" },
  { label: "Writing", key: "writingGrade" },
];

function getDisplayValue(value: null | boolean | string | number) {
  if (value === null) {
    return "None";
  }
  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }
  return value;
}

export interface StudentDetailPopoverProps
  extends Omit<PopoverBaseProps, "variant"> {
  student: Student;
}

export const StudentDetailPopover: FC<StudentDetailPopoverProps> = ({
  student,
  ...rest
}) => {
  const [infoSelected, setInfoSelected] = useState(true);
  const selectInfo = useCallback(() => setInfoSelected(true), []);
  const selectScore = useCallback(() => setInfoSelected(false), []);

  const studentDetails = infoSelected
    ? infoLabels.map(({ label, key }) => ({
        label,
        value: getDisplayValue(student[key]),
      }))
    : scoreLabels.map(({ label, key }) => ({
        label,
        value: getDisplayValue(student.studentPerformance[key]),
      }));

  return (
    <PopoverBase variant="dashboard" {...rest}>
      <PopoverHeader>
        <Heading>{formatName(student)}</Heading>
        <IdAndGrade>
          {/* Break the line at "-" if id is too long to fit both */}
          <span>{student.id}</span> <span>- {student.gradeLevel}</span>
        </IdAndGrade>
        <div>
          <SelectionButton selected={infoSelected} onClick={selectInfo}>
            Info
          </SelectionButton>
          <SelectionButton selected={!infoSelected} onClick={selectScore}>
            Score
          </SelectionButton>
        </div>
      </PopoverHeader>
      <StudentDetails>
        {studentDetails.map(({ label, value }) => (
          <Fragment key={label}>
            <dt>{label}</dt>
            <dd>{value}</dd>
          </Fragment>
        ))}
      </StudentDetails>
    </PopoverBase>
  );
};

export default styled(StudentDetailPopover)``;

const PopoverHeader = styled.div`
  text-align: center;
`;

const Heading = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
`;

const IdAndGrade = styled.div`
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
  span {
    white-space: nowrap;
  }
`;

const SelectionButton = styled(Button)<{ selected: boolean }>`
  width: auto;
  height: auto;
  padding: 0.5em 1.1em;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ selected }) => (selected ? "#000" : "#fff")};
  background-color: ${({ selected }) => (selected ? "#d3ef58" : "#4d4d4d")};
`;

const StudentDetails = styled.dl`
  display: flex;
  flex-direction: column;
  margin: 0.75rem 0 0.5rem;
  dt {
    margin-top: 10px;
    color: #d3ef58;
    line-height: 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
  dd {
    margin-left: 1rem;
    line-height: 1rem;
    font-size: 0.75rem;
    font-weight: 300;
  }
`;
