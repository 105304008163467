enum Subject {
  MATH = "Math",
  SCIENCE = "Science",
  HISTORY = "History",
  SPELLING = "Spelling",
  GRAMMAR = "Grammar",
  WRITING = "Writing",
}

export default Subject;

export const subjects = Object.values(Subject);
