import { FC, useMemo } from "react";
import Row from "@emberex/components/lib/Row";
import styled from "styled-components";
import StudentDemographicChart, {
  LabelledChartData,
} from "./StudentDemographicChart";
import { blue, mangenta, purple } from "../constants/PolarChartColors";
import Student from "../types/Student";

export interface StudentDemographicsData {
  genderChartData: {
    maleStudents: readonly Student[];
    femaleStudents: readonly Student[];
    otherGenderStudents: readonly Student[];
  };
  ellChartData: {
    ellStudents: readonly Student[];
    nonEllStudents: readonly Student[];
  };
  plan504ChartData: {
    hasPlanStudents: readonly Student[];
    hasNoPlanStudents: readonly Student[];
  };
}

export interface StudentDemographicChartsProps {
  demographicsData: StudentDemographicsData;
}

export const StudentDemographicCharts: FC<StudentDemographicChartsProps> = ({
  demographicsData,
  ...rest
}) => {
  const { ellChartData, genderChartData, plan504ChartData } = demographicsData;
  const genderData = useMemo((): LabelledChartData[] => {
    const { maleStudents, femaleStudents, otherGenderStudents } =
      genderChartData;
    const total =
      maleStudents.length +
        femaleStudents.length +
        otherGenderStudents.length ?? 1;
    const data = [
      {
        label: "Male",
        students: maleStudents,
        color: blue,
        value: (maleStudents.length / total) * 100,
      },
      {
        label: "Female",
        students: femaleStudents,
        color: mangenta,
        value: (femaleStudents.length / total) * 100,
      },
    ];
    if (otherGenderStudents.length > 0) {
      data.push({
        label: "Other",
        students: otherGenderStudents,
        color: purple,
        value: (otherGenderStudents.length / total) * 100,
      });
    }
    return data;
  }, [genderChartData]);

  const ellData = useMemo((): LabelledChartData[] => {
    const { ellStudents, nonEllStudents } = ellChartData;
    const total = ellStudents.length + nonEllStudents.length ?? 1;
    return [
      {
        label: "ELL Student",
        students: ellStudents,
        color: blue,
        value: (ellStudents.length / total) * 100,
      },

      {
        label: "Not ELL Student",
        students: nonEllStudents,
        color: mangenta,
        value: (nonEllStudents.length / total) * 100,
      },
    ];
  }, [ellChartData]);

  const plan504Data = useMemo((): LabelledChartData[] => {
    const { hasPlanStudents, hasNoPlanStudents } = plan504ChartData;
    const total = hasNoPlanStudents.length + hasPlanStudents.length ?? 1;

    return [
      {
        label: "Has 504 Plan",
        students: hasPlanStudents,
        color: blue,
        value: (hasPlanStudents.length / total) * 100,
      },
      {
        label: "No 504 Plan",
        students: hasNoPlanStudents,
        color: mangenta,
        value: (hasNoPlanStudents.length / total) * 100,
      },
    ];
  }, [plan504ChartData]);

  return (
    <Row {...rest}>
      <StudentDemographicChart title="Gender" chartData={genderData} />
      <StudentDemographicChart title="ELL" chartData={ellData} />
      <StudentDemographicChart title="504 Plan" chartData={plan504Data} />
    </Row>
  );
};

export default styled(StudentDemographicCharts)`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(327px, 1fr));
  gap: 1.5rem;
`;
