import { LetterGrade } from "../constants/LetterGrade";

/**
 * Return a GPA scale value (0.0 - 4.0) for a letter grade.
 * Values based off of https://pages.collegeboard.org/how-to-convert-gpa-4.0-scale, minus D+ and E
 * @param letterGrade
 */
export default function getGPAScaleForLetterGrade(
  letterGrade: LetterGrade
): number {
  switch (letterGrade) {
    case LetterGrade.APlus:
    case LetterGrade.A:
      return 4.0;
    case LetterGrade.AMinus:
      return 3.7;
    case LetterGrade.BPlus:
      return 3.3;
    case LetterGrade.B:
      return 3.0;
    case LetterGrade.BMinus:
      return 2.7;
    case LetterGrade.CPlus:
      return 2.3;
    case LetterGrade.C:
      return 2.0;
    case LetterGrade.CMinus:
      return 1.7;
    case LetterGrade.D:
      return 1.0;
    case LetterGrade.F:
    default:
      return 0.0;
  }
}
