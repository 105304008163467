import Student from "../types/Student";

export function getStudentsByKeyValue<T extends Student, Key extends keyof T>(
  students: readonly T[],
  key: Key,
  value?: T[Key]
): readonly Student[] {
  return students.filter((student) => {
    if (value === undefined) {
      return student[key];
    }
    return student[key] === value;
  });
}
