import { FC } from "react";
import styled from "styled-components";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import { Grade } from "../constants/Grade";
import StudentGradeTotals from "./StudentGradeTotals";
import StudentDemographicCharts, {
  StudentDemographicsData,
} from "./StudentDemographicCharts";
import RaceAreaChart, { RaceAreaChartProps } from "./RaceAreaChart";
import DisabilitiesChart, { DisabilityData } from "./DisabilitiesChart";
import PerformersChart, { PerformersChartData } from "./PerformersChart";
import SubjectByGradeChart from "./SubjectByGradeChart";
import StudentPerformance from "../types/StudentPerformance";

export interface DashboardChartsData {
  /**
   * Data for the StudentGradeTotals
   */
  gradeCounts: Record<Grade, number>;

  /**
   * Data for the StudentDemographicCharts
   */
  demographicData: StudentDemographicsData;

  /**
   * Data for the RaceAreaChart
   */
  raceChartData: RaceAreaChartProps["values"];

  /**
   * Data for the DisabilitiesChart
   */
  disabilityData: DisabilityData[];

  /**
   * Data for the PerformersChart
   */
  performanceChartData: PerformersChartData;

  /**
   * Data for the SubjectByGradeChart
   */
  subjectByGradeData: Record<Grade, StudentPerformance[]>;
}

export interface DashboardChartsProps {
  data: DashboardChartsData;
  filteredGrades: ReadonlyArray<Grade>;
  onGradeFiltered(grade: Grade): void;
  globalSelectedGrade: Grade | null;
}

export const DashboardCharts: FC<DashboardChartsProps> = ({
  data,
  filteredGrades,
  onGradeFiltered,
  globalSelectedGrade,
  ...rest
}) => {
  const {
    gradeCounts,
    demographicData,
    raceChartData,
    disabilityData,
    performanceChartData,
    subjectByGradeData,
  } = data;

  return (
    <Root {...rest}>
      <ChartRow>
        <StudentGradeTotals
          gradeCounts={gradeCounts}
          selectedGrade={globalSelectedGrade}
        />
      </ChartRow>
      <ChartRow>
        <StudentDemographicCharts demographicsData={demographicData} />
      </ChartRow>
      <ChartRow>
        <RaceAreaChart values={raceChartData} />
      </ChartRow>
      <ChartRow>
        <DisabilitiesChart data={disabilityData} />
        <PerformersChart data={performanceChartData} />
      </ChartRow>
      <ChartRow>
        <SubjectByGradeChart
          data={subjectByGradeData}
          filteredGrades={filteredGrades}
          onGradeFiltered={onGradeFiltered}
          disableGradeToggles={globalSelectedGrade !== null}
        />
      </ChartRow>
    </Root>
  );
};

export default styled(DashboardCharts)``;

const Root = styled(Column)`
  width: 100%;
  flex: 1 0 auto;
  justify-content: center;
  > :first-child {
    padding-top: 0;
  }
`;

const ChartRow = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 0;

  ${DisabilitiesChart} {
    flex: 2 2 0;
    width: 840px;
    height: 540px;
    margin-right: 1.5rem;
    @media screen and (max-width: 800px) {
      flex: 1 0 100%;
      margin: 0 0 1.5rem;
    }
  }
  ${PerformersChart} {
    flex: 1 1 0;
    width: 408px;
    height: 540px;
  }

  ${RaceAreaChart} {
    min-height: 475px;
  }
  ${SubjectByGradeChart} {
    height: 475px;
  }
`;
