import { FC, useState, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Overlay from "@emberex/components/lib/Overlay";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { IMPORT_ISLAND, DASHBOARD_DISTRICT } from "../constants/routes";
import { BreakPoint } from "../constants/BreakPoint";
import ImportDashboardIslands from "../components/ImportDashboardIslands";
import IslandLinkCard, { IslandOption } from "../components/IslandLinkCard";
import MobileImportDashboardIslands from "../components/MobileImportDashboardIslands";
import IslandLink from "../components/IslandLink";

const IslandSelect: FC = (props) => {
  const navigate = useNavigate();
  useDocumentTitle("Home");
  const [islandOption, setIslandOption] = useState<IslandOption | null>(null);

  const handleIslandCardClick = useCallback(() => {
    navigate(islandOption === "import" ? IMPORT_ISLAND : DASHBOARD_DISTRICT);
  }, [navigate, islandOption]);

  return (
    <div {...props}>
      {islandOption && (
        <Overlay>
          <LinkContainer>
            <IslandLinkCard
              islandOption={islandOption}
              onClose={() => setIslandOption(null)}
              onCardButtonClick={handleIslandCardClick}
            />
          </LinkContainer>
        </Overlay>
      )}

      {/* Visible above smallest breakpoint */}
      <Islands>
        <ImportLink option="import" onClick={() => setIslandOption("import")} />
        <DashLink
          option="dashboard"
          onClick={() => setIslandOption("dashboard")}
        />
      </Islands>
      {/* Visible above smallest breakpoint */}

      {/* Only visible at smallest breakpoint */}
      <MobileImportDashboardIslands
        onDashboardLinkClick={() => setIslandOption("dashboard")}
        onImportLinkClick={() => setIslandOption("import")}
      />
      {/* Only visible at smallest breakpoint */}
    </div>
  );
};

const Islands = styled(ImportDashboardIslands)`
  justify-content: center;
  margin: 300px 0 200px 0;
  transition: all 0.3s ease;
`;

const ImportLink = styled(IslandLink)`
  position: absolute;
  width: 16%;
  top: 24%;
  left: 29%;
`;

const DashLink = styled(IslandLink)`
  position: absolute;
  width: 25%;
  right: 21.5%;
  bottom: 22%;
`;

const LinkContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default styled(IslandSelect)`
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;

  ${MobileImportDashboardIslands} {
    display: none;
  }

  @media ${BreakPoint.LARGE} {
    ${Islands} {
      margin-top: 225px;
    }
  }

  @media ${BreakPoint.MEDIUM} {
    ${Islands} {
      margin-top: 175px;
    }
  }

  @media ${BreakPoint.SMALL} {
    ${MobileImportDashboardIslands} {
      display: block;
      margin: 100px auto 60px auto;
    }

    ${Islands} {
      display: none;
    }
  }
`;
