import Row from "@emberex/components/lib/Row";
import { FC, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AnimatedIsland from "../../../components/AnimatedIsland";
import UploadControls from "../../../components/UploadControls";
import { BreakPoint } from "../../../constants/BreakPoint";
import { IMPORT_ISLAND_UPLOAD } from "../../../constants/routes";
import { useUploadContext } from "../../../context/uploadContext";
import { useEventListener } from "../../../hooks/useEventListener";

const Upload: FC = (props) => {
  const [year, setYear] = useState("");
  const [semester, setSemester] = useState("");
  const [hideDropzone, setHideDropzone] = useState(false);
  const { saveFile } = useUploadContext();

  const navigate = useNavigate();

  const handleFileUpload = useCallback(
    (file: File): void => {
      if (year && semester) {
        saveFile(file);
        navigate(IMPORT_ISLAND_UPLOAD);
      } else {
        alert("School year and semester are required fields.");
      }
    },
    [year, semester, saveFile, navigate]
  );

  const onLargeBreakPoint = (event: MediaQueryList) => {
    if (event.matches) {
      setHideDropzone(true);
    } else {
      setHideDropzone(false);
    }
  };

  const largeMediaQuery = window.matchMedia(BreakPoint.LARGE);

  useEventListener(largeMediaQuery, "change", () =>
    onLargeBreakPoint(largeMediaQuery)
  );

  useEffect(() => {
    onLargeBreakPoint(largeMediaQuery);
  }, [largeMediaQuery]);

  return (
    <Root {...props}>
      <UploadControls
        onHandleYearChange={setYear}
        onHandleSemesterChange={setSemester}
        onHandleFileUpload={handleFileUpload}
        uploadEnabled={!!year && !!semester}
        hideDropZone={hideDropzone}
      />
      <AnimatedIsland islandOption="import" />
    </Root>
  );
};

export default styled(Upload)`
  flex: 1;
  width: 100vw;

  ${AnimatedIsland} {
    height: 908px;
    width: 1052px;
    position: absolute;
    top: 67px;
    left: 47%;
    z-index: 0;
    transition: all 0.8s ease;
    transform: scale(1.3);
  }

  ${UploadControls} {
    margin: 200px 0 0 229px;
    transition: all 0.8s ease;
    z-index: 1;
  }

  @media ${BreakPoint.LARGE} {
    ${UploadControls} {
      margin: 160px 0 0 84px;
    }

    ${AnimatedIsland} {
      height: 653px;
      width: 757px;
      top: 140px;
      left: 49%;
    }
  }

  @media ${BreakPoint.MEDIUM} {
    ${UploadControls} {
      margin-left: 60px;
    }

    ${AnimatedIsland} {
      top: 400px;
      left: 34%;
    }
  }

  @media ${BreakPoint.SMALL} {
    ${AnimatedIsland} {
      display: none;
    }

    ${UploadControls} {
      margin-left: 24px;
    }
  }
`;

const Root = styled(Row)`
  &.slide-enter {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translate(-75%, 0);
  }

  &.slide-enter-active {
    transform: translate(0, 0);

    transition: transform 1s ease;
  }
  &.slide-exit {
    transform: translate(0, 0);
  }
  &.slide-exit-active {
    transform: translate(-100%, 0);
    position: absolute;
    top: 0;
    left: 0;

    transition: transform 1s ease;
    ${AnimatedIsland} {
      display: none;
    }
  }
`;
