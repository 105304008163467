import { isNumericGrade, PerformanceGrade } from "../types/PerformanceGrade";
import getGPAScaleForLetterGrade from "./getGPAScaleForLetterGrade";

export default function getGradeAsPercent(
  grade: PerformanceGrade | null
): number {
  if (grade === null) {
    return 0;
  }
  if (isNumericGrade(grade)) {
    return Math.min(grade, 100);
  }
  // If we're in letter grade mode, covert to the GPA and get the percentage out of 4.0.
  // This is probably not the best solution, but it allows us to mix letter and number grades
  return (getGPAScaleForLetterGrade(grade) / 4.0) * 100;
}
