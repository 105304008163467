import { FC, useMemo } from "react";
import styled, { css } from "styled-components";
import { Grade, GRADES } from "../constants/Grade";
import DashboardPane from "./DashboardPane";
import ChartTitle from "./ChartTitle";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import DonutChart, { donutDiameter } from "./DonutChart";

export interface StudentGradeTotalsProps {
  gradeCounts: Record<Grade, number>;
  selectedGrade: Grade | null;
}

export const StudentGradeTotals: FC<StudentGradeTotalsProps> = ({
  gradeCounts,
  selectedGrade,
  ...rest
}) => {
  const total = useMemo(
    () =>
      Object.values(gradeCounts).reduce((count, current) => count + current, 0),
    [gradeCounts]
  );
  const gradeList = useMemo(
    () => (selectedGrade ? [selectedGrade] : GRADES),
    [selectedGrade]
  );
  return (
    <Root {...rest}>
      <ChartTitle>Student Totals</ChartTitle>
      <Charts singleChart={gradeList.length === 1}>
        {gradeList.map((grade) => (
          <Chart key={grade}>
            <DonutChart total={total} chartCount={gradeCounts[grade]} />
            <ChartName>{grade}</ChartName>
          </Chart>
        ))}
      </Charts>
    </Root>
  );
};

export default styled(StudentGradeTotals)``;

const Root = styled(DashboardPane)`
  flex-direction: column;
  padding: 1.5rem 1.875rem;
  width: 100%;
  ${ChartTitle} {
    margin-bottom: 1.5rem;
  }
`;

const Charts = styled(Row)<{ singleChart: boolean }>`
  display: grid;
  row-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(${donutDiameter}, 1fr));
  ${(props) =>
    props.singleChart &&
    css`
      grid-template-columns: 1fr;
    `}
`;

const Chart = styled(Column)``;

const ChartName = styled.span`
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
`;
