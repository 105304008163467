import { useCallback } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import Row from "@emberex/components/lib/Row";
import { useEventListener } from "../../hooks/useEventListener";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { Upload, Import, Complete } from "./stages";
import { useUploadContext } from "../../context/uploadContext";
import "../../index.css";
import { usePreviousValue } from "../../hooks/usePreviousValue";

export default function ImportIsland() {
  const { file } = useUploadContext();
  useDocumentTitle("Import Island");

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      event.preventDefault();

      // Modern browsers don't allow a custom message, but old ones may show it
      if (file) {
        event.returnValue =
          "Are you sure you want to leave the page? Uploads and settings won't be saved";
        return event.returnValue;
      }
    },
    [file]
  );

  useEventListener(window, "beforeunload", handleBeforeUnload);

  const location = useLocation();
  const { pathname } = location;
  const previousPath = usePreviousValue(pathname);

  return (
    <Root>
      <TransitionGroup element={null}>
        <CSSTransition key={pathname} classNames="slide" timeout={1000}>
          <Routes location={location}>
            <Route path="/" element={<Upload />} />
            <Route
              path="import"
              element={<Import previousPath={previousPath} />}
            />
            <Route path="import-complete" element={<Complete />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </Root>
  );
}

const Root = styled(Row)`
  flex: 1;
  width: 100vw;
`;
