import { FC } from "react";
import styled from "styled-components";
import dashboardDistrictIslandSrc from "../images/dashboard-district-island.png";
import importIslandImageSrc from "../images/import-island-aerial.png";
import noop from "../utils/noop";
import { BreakPoint } from "../constants/BreakPoint";

const cardOptions = {
  import: {
    title: "Import Island",
    img: importIslandImageSrc,
    description: `Visit Import Island and see how magical your CSVs can 
      really be when you use our custom importer.`,
  },
  dashboard: {
    title: "Dashboard District",
    img: dashboardDistrictIslandSrc,
    description: `Enjoy the stunning views from Dashboard District. 
    Your student data never looked so good.`,
  },
};

type CardOption = keyof typeof cardOptions;

export interface IslandCardProps {
  cardOption: CardOption;
  onCardClick?(): unknown;
}

export const IslandCard: FC<IslandCardProps> = ({
  cardOption = "import",
  onCardClick = noop,
  ...props
}) => {
  const { title, img, description } = cardOptions[cardOption];
  const importIslandOption = cardOption === "import";
  return (
    <div {...props} onClick={onCardClick}>
      <CardTitle>{title}</CardTitle>
      <Island src={img} alt="island" importIslandOption={importIslandOption} />
      <Description>{description}</Description>
    </div>
  );
};

const Island = styled.img<{ importIslandOption?: boolean }>`
  height: 225px;
  width: ${({ importIslandOption }) =>
    importIslandOption ? "261px" : "295px"};

  @media ${BreakPoint.SMALL} {
    height: 112px;
    width: ${({ importIslandOption }) =>
      importIslandOption ? "130px" : "148px"};
  }
`;

const CardTitle = styled.h2`
  font-size: 28px;
  font-family: "Train One";
  font-weight: 400;
  color: #d3ef58;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 20px 0 24px 0;
`;

const Description = styled.p`
  color: white;
  text-align: center;
  font-size: 20px;
  font-family: "Muli";
  font-weight: 300;
  width: 327px;
  margin-top: 20px;
`;

export default styled(IslandCard)`
  background-color: #262626;
  width: 400px;
  height: 440px;
  border-radius: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: inset 1px 1px 20px 14px rgba(0, 0, 0, 0.22);
  }

  @media ${BreakPoint.LARGE} {
    width: 354px;
    height: 402px;

    ${CardTitle} {
      font-size: 20px;
    }
    ${Description} {
      font-size: 16px;
      width: 300px;
    }
  }

  @media ${BreakPoint.MEDIUM} {
    ${CardTitle} {
      font-size: 20px;
    }

    ${Description} {
      font-size: 16px;
      margin-top: 20px;
      width: 260px;
    }
  }

  @media ${BreakPoint.SMALL} {
    width: 296px;
    height: 289px;
  }
`;
