import {
  createContext,
  useContext,
  useState,
  FC,
  PropsWithChildren,
} from "react";

export interface UploadContextValue {
  file: File | null;
  saveFile: (newFile: File | null) => void;
}

const UploadContext = createContext<UploadContextValue | null>(null);

export const UploadContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [file, setFile] = useState<File | null>(null);

  return (
    <UploadContext.Provider
      value={{
        file,
        saveFile: (newFile) => setFile(newFile),
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export function useUploadContext(): UploadContextValue {
  const contextValue = useContext(UploadContext);

  if (!contextValue) {
    throw new Error(
      "Hook useUploadContext must be a child of UploadContext.Provider"
    );
  }

  return contextValue;
}
