import { FC, useMemo } from "react";
import styled from "styled-components";
import PolarAreaChart, { PolarAreaChartData } from "./PolarAreaChart";
import DashboardPane from "./DashboardPane";
import Row from "@emberex/components/lib/Row";
import ChartTitle from "./ChartTitle";
import ChartColorKey from "./ChartColorKey";

export interface LabelledChartData extends PolarAreaChartData {}

export interface StudentDemographicChartProps {
  title: string;
  chartData: ReadonlyArray<LabelledChartData>;
}

export const StudentDemographicChart: FC<StudentDemographicChartProps> = ({
  title,
  chartData,
  ...rest
}) => {
  // The order on the polar chart is reversed, so this needs to be swapped.
  const chartPresentationOrder = useMemo(
    () => [...chartData].reverse(),
    [chartData]
  );
  return (
    <DashboardPane {...rest}>
      <TitleAndKey>
        <ChartTitle>{title}</ChartTitle>
        <ChartColorKey colors={chartData} />
      </TitleAndKey>
      <PolarAreaChart title={title} values={chartPresentationOrder} />
    </DashboardPane>
  );
};

export default styled(StudentDemographicChart)`
  padding: 1.5rem;
  flex-direction: column;
  align-items: center;
`;

const TitleAndKey = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
  width: 100%;
`;
