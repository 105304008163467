import { FC, useMemo } from "react";
import styled from "styled-components";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import Column from "@emberex/components/lib/Column";

const options: ApexOptions = {
  legend: {
    show: false,
  },
  colors: ["#5CE5B8", "#000000"],
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    show: false,
  },
  plotOptions: {
    pie: {
      donut: {
        size: "60%",
      },
    },
  },
};

export const donutDiameter = "162px";

export interface DonutChartProps {
  chartCount: number;
  total: number;
}

export const DonutChart: FC<DonutChartProps> = ({
  chartCount,
  total,
  ...rest
}) => {
  const series = useMemo(() => {
    // The series will be the % "consumed" out of 100%.
    const consumed = (chartCount / Math.max(total, 1)) * 100;
    return [consumed, 100 - consumed];
  }, [total, chartCount]);

  return (
    <Root {...rest}>
      <ReactApexChart
        series={series}
        options={options}
        type="donut"
        height={donutDiameter}
        width={donutDiameter}
      />
      <Count>{chartCount}</Count>
    </Root>
  );
};

export default styled(DonutChart)``;

const Root = styled(Column)`
  justify-content: center;
  align-items: center;
`;

const Count = styled(Column)`
  color: #ffffff;
  font-family: Roboto;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 2.375rem;
  text-align: center;
  position: absolute;
`;
