import { FC, useCallback, useRef, useState, MouseEvent } from "react";
import styled from "styled-components";
import StudentOverallScoreChart from "./StudentOverallScoreChart";
import DashboardPane from "./DashboardPane";
import Column from "@emberex/components/lib/Column";
import ChartTitle from "./ChartTitle";
import Row from "@emberex/components/lib/Row";
import Student from "../types/Student";
import { getVirtualElementInScrollingContainer } from "../utils/popoverAnchorVirtualElement";
import StudentDetailPopover, { AnchorElement } from "./StudentDetailPopover";
import { useOnOutOfBounds } from "../hooks/useOnOutOfBounds";

export interface PerformersChartData {
  /**
   * Students who got an overall score >= the average grade.
   */
  topStudents: readonly Student[];
  averageGrade: number;
}

type PopoverData = null | { anchor: AnchorElement; student: Student };

export interface PerformersChartProps {
  data: PerformersChartData;
}

export const PerformersChart: FC<PerformersChartProps> = ({
  data,
  ...rest
}) => {
  const { averageGrade, topStudents } = data;

  const [popoverData, setPopoverData] = useState<PopoverData>(null);
  const handleClosePopover = useCallback(() => setPopoverData(null), []);

  const chartsContainer = useRef<HTMLDivElement>(null);
  const overflowContainer = useRef<HTMLDivElement>(null);

  const handleStudentClick = useCallback(
    (event: MouseEvent<HTMLDivElement>, dataIndex: number) => {
      setPopoverData({
        student: topStudents[dataIndex],
        anchor: getVirtualElementInScrollingContainer({
          target: event.currentTarget,
          container: chartsContainer.current!, // Not null if we got a click
          containerPadding: 16,
        }),
      });
    },
    [topStudents]
  );

  useOnOutOfBounds(
    overflowContainer,
    !!popoverData,
    handleClosePopover,
    popoverData?.anchor as Element
  );

  return (
    <DashboardPane ref={chartsContainer} {...rest}>
      <ChartHeader>
        <ChartTitle>Performers</ChartTitle>
        <AverageScore>
          <Row>{averageGrade.toFixed(0)}</Row>
          <Row>Average Score</Row>
        </AverageScore>
      </ChartHeader>
      <StudentCharts ref={overflowContainer}>
        {topStudents.map((student, idx) => (
          <StudentOverallScoreChart
            key={student.id}
            student={student}
            animationOffset={idx}
            onClick={(e) => handleStudentClick(e, idx)}
          />
        ))}
      </StudentCharts>
      {popoverData && (
        <StudentDetailPopover
          student={popoverData.student}
          anchorElement={popoverData.anchor}
          onClose={handleClosePopover}
        />
      )}
    </DashboardPane>
  );
};

export default styled(PerformersChart)`
  flex-direction: column;
  padding: 1.5rem 0.625rem 1.5rem 1.5rem;
  height: 541px;
  width: 100%;
`;

const ChartHeader = styled(Column)``;

const AverageScore = styled(Row)`
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  > :first-child {
    font-family: "Muli";
    font-weight: 300;
    font-size: 2.875rem;
    line-height: 3.625rem;
    margin-right: 0.3125rem;
  }
  align-items: baseline;
`;

const StudentCharts = styled(Column)`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  margin-top: 2rem;
  ${StudentOverallScoreChart} + ${StudentOverallScoreChart} {
    margin-top: 1.5rem;
  }
`;
