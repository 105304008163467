import {
  createContext,
  useContext,
  useState,
  FC,
  PropsWithChildren,
} from "react";
import random from "lodash.random";
import Student from "../types/Student";
import { createStudents } from "../utils/studentFactory";

export interface StudentContextValue {
  students: Student[];
  updateStudents: (newStudents: Student[]) => void;
}

const StudentContext = createContext<StudentContextValue | null>(null);

export const StudentContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [students, setStudents] = useState<Student[]>(() =>
    createStudents(random(500, 1000))
  );

  return (
    <StudentContext.Provider
      value={{
        students,
        updateStudents: (newStudents: Student[]) => setStudents(newStudents),
      }}
    >
      {children}
    </StudentContext.Provider>
  );
};

export function useStudents(): StudentContextValue {
  const contextValue = useContext(StudentContext);

  if (!contextValue) {
    throw new Error(
      "Hook useStudents must be a child of studentContext.Provider"
    );
  }

  return contextValue;
}
