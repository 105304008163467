export enum Grade {
  KINDERGARTEN = "Kindergarten",
  FIRST = "1st Grade",
  SECOND = "2nd Grade",
  THIRD = "3rd Grade",
  FOURTH = "4th Grade",
  FIFTH = "5th Grade",
  SIXTH = "6th Grade",
}

export const GRADES = Object.values(Grade);

export const GradeAliases: Record<Grade, string[]> = {
  [Grade.KINDERGARTEN]: ["K"],
  [Grade.FIRST]: ["1", "1st"],
  [Grade.SECOND]: ["2", "2nd"],
  [Grade.THIRD]: ["3", "3rd"],
  [Grade.FOURTH]: ["4", "4th"],
  [Grade.FIFTH]: ["5", "5th"],
  [Grade.SIXTH]: ["6", "6th"],
};
