import { FC } from "react";
import styled from "styled-components";
import CloseButton from "./CloseButton";
import importIsland from "../images/import-island-aerial.png";
import dashboardDistrict from "../images/dashboard-district-island.png";
import Button from "./Button";
import { BreakPoint } from "../constants/BreakPoint";
import noop from "../utils/noop";

export type IslandOption = "import" | "dashboard";

export interface IslandLinkCardProps {
  islandOption: IslandOption;
  onClose?: () => void;
  onCardButtonClick?: () => void;
}

const IslandLinkCard: FC<IslandLinkCardProps> = ({
  islandOption,
  onClose = noop,
  onCardButtonClick = noop,
  ...rest
}) => {
  const importIslandOption = islandOption === "import";
  const linkDescription = importIslandOption
    ? "Visit Import Island and see how magical your CSVs can really be when you use our custom importer."
    : "Enjoy the stunning views from Dashboard District. Your student data never looked so good.";

  return (
    <div {...rest}>
      <Close onClick={onClose} />
      <IslandImage
        src={importIslandOption ? importIsland : dashboardDistrict}
        alt={importIslandOption ? "import island" : "dashboard district"}
        islandOption={importIslandOption}
      />
      <TextContainer>{linkDescription}</TextContainer>
      <NeonButton onClick={onCardButtonClick}>
        {importIslandOption ? "Yes, please!" : "Let me in!"}
      </NeonButton>
    </div>
  );
};

const Close = styled(CloseButton)`
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
`;

const IslandImage = styled.img<{ islandOption: boolean }>`
  height: 225px;
  width: ${({ islandOption }) => (islandOption ? "261px" : "295px")};
  margin-top: ${({ islandOption }) => (islandOption ? "20px" : "28px")};
  margin-bottom: 42px;
  transition: all 0.4s ease;

  @media ${BreakPoint.SMALL} {
    height: ${({ islandOption }) => (islandOption ? "155px" : "152px")};
    width: ${({ islandOption }) => (islandOption ? "180px" : "200px")};
    margin-top: 44px;
    margin-bottom: ${({ islandOption }) => (islandOption ? "36px" : "39px")};
  }
`;

const TextContainer = styled.div`
  display: flex;
  margin: 0 60px;
  font-weight: 300;
  color: white;
  font-size: 32px;
`;

const NeonButton = styled(Button)`
  height: 41px;
  width: 141px;
  background-color: #d3ef58;
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  margin: auto 0 33px 0;
`;

export default styled(IslandLinkCard)`
  position: relative;
  height: 613px;
  max-width: 538px;
  width: 100%;
  border-radius: 37px;
  background-color: #262626;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s ease;
  font-family: "Muli";

  @media (max-width: 450px) {
    height: 461px;

    ${TextContainer} {
      width: 90%;
      font-size: 20px;
      text-align: center;
    }

    ${NeonButton} {
      margin-bottom: 24px;
    }
  }
`;
