import StudentPerformance from "../../types/StudentPerformance";
import { PerformersChartData } from "../../components/PerformersChart";
import Student from "../../types/Student";
import { useMemo } from "react";
import getGradeAsPercent from "../../utils/getGradeAsPercent";
import useSort from "@emberex/react-utils/lib/useSort";

/**
 * The number of "Top Students" to show.
 */
const TOP_STUDENT_LIMIT = 30;

function overallGradeDesc(
  studentA: { studentPerformance: Pick<StudentPerformance, "overallGrade"> },
  studentB: { studentPerformance: Pick<StudentPerformance, "overallGrade"> }
): number {
  return (
    getGradeAsPercent(studentB.studentPerformance.overallGrade) -
    getGradeAsPercent(studentA.studentPerformance.overallGrade)
  );
}

export default function usePerformersChart(
  students: readonly Student[]
): PerformersChartData {
  // The cast to a mutable type is safe becasue useSort first copies the array
  const sortedStudents = useSort(students as Student[], overallGradeDesc);

  const averageGrade = useMemo(() => {
    return (
      students
        .map((student) =>
          getGradeAsPercent(student.studentPerformance.overallGrade)
        )
        .reduce((total, current) => total + current, 0) / students.length
    );
  }, [students]);

  const topStudents = useMemo(() => {
    return sortedStudents
      .filter(
        (student) => student.studentPerformance.overallGrade >= averageGrade
      )
      .slice(0, TOP_STUDENT_LIMIT);
  }, [sortedStudents, averageGrade]);

  return {
    averageGrade,
    topStudents,
  };
}
