import { FC, PropsWithChildren, useState } from "react";
import styled from "styled-components";
import { useLottie } from "lottie-react";
import Row from "@emberex/components/lib/Row";
import importIslandLottieJsonSrc from "../images/lottie/ImportIsland_lottie.json";
import mobileDashboardDistrictSrc from "../images/lottie/ImportIsland_Mobile_Island-2.json";
import mobileImportIslandLottieSrc from "../images/lottie/ImportIsland_Mobile_Island-1.json";
import { IslandOption } from "../types/IslandOption";
import { useTimeout } from "../hooks/useTimeout";

export interface AnimatedIslandProps extends PropsWithChildren {
  islandOption: IslandOption;
}

const AnimatedIsland: FC<AnimatedIslandProps> = ({
  islandOption,
  children,
  ...rest
}) => {
  const [playing, setPlaying] = useState(true);

  const animationData =
    islandOption === "dashboard"
      ? mobileDashboardDistrictSrc
      : islandOption === "import"
      ? mobileImportIslandLottieSrc
      : importIslandLottieJsonSrc;

  const { View, play, stop } = useLottie(
    {
      animationData,
      loop: true,
      autoplay: true,
    },
    {
      width: "100%",
    }
  );

  const handleStop = () => {
    setPlaying(false);
    stop();
  };

  const handlePlay = () => {
    setPlaying(true);
    play();
  };

  useTimeout({
    timeoutFn: handleStop,
    disabled: !playing,
    timeoutMillis: 30000,
  });

  useTimeout({
    timeoutFn: handlePlay,
    disabled: playing,
    timeoutMillis: 5000,
  });

  return (
    <Row {...rest}>
      {View}
      {children}
    </Row>
  );
};

export default styled(AnimatedIsland)`
  width: 300px;
`;
