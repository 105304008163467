import { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Grade } from "../constants/Grade";
import StudentPerformance from "../types/StudentPerformance";
import DashboardPane from "./DashboardPane";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import ChartTitle from "./ChartTitle";
import GradeToggleButtons from "./GradeToggleButtons";
import getGradeAsPercent from "../utils/getGradeAsPercent";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Subject, { subjects } from "../constants/Subject";

const colors = [
  "#D3EF58",
  "#5CE5B8",
  "#5CE5E5",
  "#5CB8E5",
  "#5C5CE5",
  "#8A5CE5",
];

const options: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    fontFamily: "Muli",
    zoom: {
      enabled: false,
    },
    redrawOnParentResize: true,
  },
  fill: {
    opacity: 0.25,
    colors,
  },
  markers: {
    strokeColors: colors,
  },
  grid: {
    borderColor: "#000",
    padding: {
      left: 35,
      bottom: 10,
    },
  },
  legend: {
    show: false,
  },
  yaxis: {
    title: {
      text: "score (percentage)",
      style: {
        color: "#fff",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "0.75rem",
      },
      rotate: 90,
      offsetX: -30,
    },
    labels: {
      style: {
        colors: "#fff",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "0.875rem",
      },
      offsetX: 25,
    },
  },
  xaxis: {
    tickAmount: 7,
    min: 0,
    max: 7,
    title: {
      text: "Subject",
      style: {
        color: "#fff",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "0.75rem",
      },
      offsetY: 25,
    },
    labels: {
      style: {
        colors: "#fff",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "0.875rem",
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    type: "category",
    categories: subjects,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
};

export type GradePerformance = Record<Grade, StudentPerformance[]>;

export interface SubjectByGradeChartProps {
  data: GradePerformance;
  filteredGrades: ReadonlyArray<Grade>;
  onGradeFiltered(grade: Grade): void;
  disableGradeToggles?: boolean;
}

type ScoreAccumulation = { [key: number]: number };

/**
 * Transform the StudentPerformance scores into the series that the bubble graph expects.
 * It will be returned in the following format: [[x, y, z], ...], where x is the category, y
 * is the score, and z is the number of students with y score (i.e. the size of the bubble)
 */
function getSeriesFor(
  subject: Subject,
  performanceData: StudentPerformance[],
  field: keyof StudentPerformance
) {
  const scores = performanceData
    .flatMap((perfData) => perfData[field])
    .map(getGradeAsPercent);
  const scoreDistribution = scores.reduce((obj: ScoreAccumulation, current) => {
    const currentCount = obj[current] ?? 0;
    return {
      ...obj,
      [current]: currentCount + 1,
    };
  }, {});
  const x = Math.max(0, subjects.indexOf(subject)) + 1;
  return Object.entries(scoreDistribution).map(([y, z]) => [x, +y, z]);
}

function createSeries(
  subject: Subject,
  performanceData: StudentPerformance[],
  field: keyof StudentPerformance
) {
  return {
    name: subject,
    data: getSeriesFor(subject, performanceData, field),
  };
}

export const SubjectByGradeChart: FC<SubjectByGradeChartProps> = ({
  data,
  filteredGrades,
  onGradeFiltered,
  disableGradeToggles,
  ...rest
}) => {
  // ApexCharts has a feature to rotate labels when they overlap. But it has a
  // bug that our current setup consistently triggers due to leaving space
  // before the first data item (avoiding the left half of it being cut off):
  // https://github.com/apexcharts/apexcharts.js/issues/446
  //
  // For now, we have to manually force the labels to rotate on narrow viewports
  const [rotateLabels, setRotateLabels] = useState(window.innerWidth < 550);

  useEffect(() => {
    const handleWindowResize = () => setRotateLabels(window.innerWidth < 550);
    window.addEventListener("resize", handleWindowResize, { passive: true });
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const optionsWithRotateHack = useMemo(() => {
    return {
      ...options,
      xaxis: {
        ...options.xaxis,
        labels: { ...options.xaxis!.labels, rotateAlways: rotateLabels },
      },
    };
  }, [rotateLabels]);

  const filteredData = useMemo(() => {
    return Object.entries(data)
      .filter(([grade]) => filteredGrades.includes(grade as Grade))
      .flatMap(([, performance]) => performance);
  }, [data, filteredGrades]);

  const chartSeries = useMemo(() => {
    return [
      createSeries(Subject.MATH, filteredData, "mathGrade"),
      createSeries(Subject.SCIENCE, filteredData, "scienceGrade"),
      createSeries(Subject.HISTORY, filteredData, "historyGrade"),
      createSeries(Subject.SPELLING, filteredData, "spellingGrade"),
      createSeries(Subject.GRAMMAR, filteredData, "grammarGrade"),
      createSeries(Subject.WRITING, filteredData, "writingGrade"),
    ];
  }, [filteredData]);

  return (
    <DashboardPane {...rest}>
      <ChartHeading>
        <ChartTitles>
          <ChartTitle>Grades</ChartTitle>
          <Subjects>
            <Row>6</Row>
            <Row>Total Subjects</Row>
          </Subjects>
        </ChartTitles>
        <GradeToggleButtons
          selected={filteredGrades}
          onToggle={onGradeFiltered}
          disabled={disableGradeToggles}
        />
      </ChartHeading>
      <ChartContainer>
        <ReactApexChart
          type="bubble"
          series={chartSeries as ApexAxisChartSeries}
          options={optionsWithRotateHack}
          height="100%"
        />
      </ChartContainer>
    </DashboardPane>
  );
};

export default styled(SubjectByGradeChart)`
  flex-direction: column;
  padding: 1.5rem;
  height: 475px;
  width: 100%;
`;

const ChartHeading = styled(Row)`
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  ${GradeToggleButtons} {
    flex: 1 1 calc(100% - 240px);
    align-self: flex-end;
  }
`;

const ChartTitles = styled(Column)``;

const Subjects = styled(Row)`
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  width: 150px;
  margin-right: 84px;
  > :first-child {
    font-family: "Muli";
    font-weight: 300;
    font-size: 2.875rem;
    line-height: 3.625rem;
    margin-right: 0.625rem;
  }
  align-items: baseline;
`;

const ChartContainer = styled(Column)`
  width: 100%;
  flex: 1 0 auto;
`;
