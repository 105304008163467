import { FC } from "react";
import styled from "styled-components";
import Row from "@emberex/components/lib/Row";

export interface ChartColorKeyProps {
  colors: ReadonlyArray<{
    label: string;
    color: string;
  }>;
}

export const ChartColorKey: FC<ChartColorKeyProps> = ({ colors, ...rest }) => {
  return (
    <Root {...rest}>
      {colors.map(({ color, label }) => (
        <KeyItem key={label}>
          <KeyColor color={color} />
          <span>{label}</span>
        </KeyItem>
      ))}
    </Root>
  );
};

export default styled(ChartColorKey)``;

const KeyItem = styled(Row)`
  color: #ffffff;
  font-size: 0.75rem;
  letter-spacing: 0;
  align-items: center;
  :not(:last-child) {
    margin-right: 0.875rem;
  }
`;

const Root = styled(Row)`
  line-height: 0.9375rem;
`;

const KeyColor = styled.div<{ color: string }>`
  height: 16px;
  width: 16px;
  background-color: ${(props) => props.color};
  margin-right: 0.625rem;
`;
