import { FC } from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import UploadForm from "./UploadForm";
import Column from "@emberex/components/lib/Column";

export interface UploadControlsProps {
  onHandleFileUpload(file: File): void;
  onHandleYearChange(year: string): void;
  onHandleSemesterChange(semester: string): void;
  uploadEnabled: boolean;
  hideDropZone?: boolean;
}

const UploadControls: FC<UploadControlsProps> = ({
  onHandleFileUpload,
  onHandleYearChange,
  onHandleSemesterChange,
  uploadEnabled,
  hideDropZone,
  ...rest
}) => (
  <Root {...rest}>
    <FormContainer>
      <Form>
        <Container>
          <Dropdown
            label="Select a year"
            data={["2016-2017", "2017-2018", "2018-2019"]}
            onChange={onHandleYearChange}
          />
        </Container>
        <Container>
          <Dropdown
            label="Select a semester"
            data={["Semester 1", "Semester 2"]}
            onChange={onHandleSemesterChange}
          />
        </Container>
        <UploadFormContainer>
          <UploadForm
            uploadEnabled={uploadEnabled}
            onHandleFileUpload={onHandleFileUpload}
            hideDropZone={hideDropZone}
          />
        </UploadFormContainer>
      </Form>
    </FormContainer>
  </Root>
);

export default styled(UploadControls)``;

const Root = styled(Column)``;

const FormContainer = styled(Column)`
  align-items: center;
`;

const Form = styled("div")``;

const Container = styled("div")`
  margin-bottom: 25px;
`;

const UploadFormContainer = styled("div")``;
