import { ComponentPropsWithoutRef, FC } from "react";
import styled, { keyframes, css } from "styled-components";
import { BreakPoint } from "../constants/BreakPoint";

export interface HamburgerButtonProps
  extends ComponentPropsWithoutRef<"button"> {
  menuOpen?: boolean;
}

const HamburgerButton: FC<HamburgerButtonProps> = ({ menuOpen, ...rest }) => (
  <button id="hamburger-button" {...rest}>
    <ColoredBar menuOpen={menuOpen} topBar />
    <ColoredBar menuOpen={menuOpen} hide />
    <ColoredBar menuOpen={menuOpen} />
  </button>
);

const forwardAnimation = (
  translateY: number,
  rotationDegree: number
) => keyframes`
  0% { transform: translateY(0) rotate(0); }
  50% {
    transform: translateY(${translateY}px);
  }
  100% {
    transform: translateY(${translateY}px) rotate(${rotationDegree}deg);
  }
`;

const reverseAnimation = (
  translateY: number,
  rotationDegree: number
) => keyframes`
  0% {
    transform: translateY(${translateY}px) rotate(${rotationDegree}deg);
  }
  50% {
    transform: translateY(${translateY}px);
  }
  100% {
    transform: translateY(0) rotate(0); 
  }
`;

interface AnimationProps {
  menuOpen?: boolean;
  hide?: boolean;
  topBar?: boolean;
}

interface AnimateBarProps extends AnimationProps {
  translateY: number;
}

const animateBar = ({
  menuOpen,
  hide,
  topBar,
  translateY,
}: AnimateBarProps) => {
  if (!hide) {
    const animationFunc = menuOpen ? forwardAnimation : reverseAnimation;
    return css`
      animation: ${animationFunc(
          topBar ? translateY : -translateY,
          topBar ? 45 : -45
        )}
        0.7s forwards;
    `;
  }
};

const ColoredBar = styled.div<AnimationProps>`
  height: 7px;
  width: 55px;
  background-color: #adf1e8;
  border-radius: 3.5px;
  transition: all 0.3s;
  transition-delay: opacity 0.3s;
  opacity: ${({ hide, menuOpen }) => (hide && menuOpen ? 0 : 1)};

  ${(props) => animateBar({ ...props, translateY: 15 })}

  @media ${BreakPoint.SMALL} {
    height: 4px;
    width: 36px;
    ${(props) => animateBar({ ...props, translateY: 10 })}
`;

export default styled(HamburgerButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 0;

  div:nth-child(2) {
    margin: 8px 0;

    @media ${BreakPoint.SMALL} {
      margin: 6px 0;
    }
  }
`;
