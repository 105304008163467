import { FC, useMemo } from "react";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import Column from "@emberex/components/lib/Column";
import DashboardCharts from "../components/DashboardCharts";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useStudents } from "../context/studentContext";
import { useGrades } from "../context/gradeContext";
import useDashboardCharts from "../hooks/chart/useDashboardCharts";
import AnimatedIsland from "../components/AnimatedIsland";

const DashboardDistrict: FC = (props) => {
  const { students } = useStudents();
  const { globalSelectedGrade, filteredGrades, updateFilteredGrades } =
    useGrades();

  useDocumentTitle("Dashboard District");

  const filteredStudents = useMemo(() => {
    if (!globalSelectedGrade) {
      return students;
    }
    return students.filter(
      (student) => student.gradeLevel === globalSelectedGrade
    );
  }, [globalSelectedGrade, students]);

  const dashboardData = useDashboardCharts({
    allStudents: students,
    filteredStudents,
  });

  return (
    <Page {...props}>
      <PageContents>
        <DashboardCharts
          globalSelectedGrade={globalSelectedGrade}
          onGradeFiltered={updateFilteredGrades}
          filteredGrades={filteredGrades}
          data={dashboardData}
        />
        <AnimatedIsland islandOption="dashboard" />
      </PageContents>
    </Page>
  );
};

export default styled(DashboardDistrict)`
  align-items: center;
  padding: 0 1.5rem;
  margin-top: 193px;

  ${AnimatedIsland} {
    width: 100%;
  }
`;

const PageContents = styled(Column)`
  flex: 1 0 auto;
  width: 100%;
  max-width: 1270px;
  margin-top: 24px;
`;
