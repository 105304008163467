import { useMemo } from "react";
import { Race, RACES } from "../../constants/Race";
import Student from "../../types/Student";
import { getStudentsByKeyValue } from "../../utils/getStudentsByKeyValue";

type RaceChartReturn = Record<Race, readonly Student[]>;

export default function useStudentRaceChart(
  students: readonly Student[]
): RaceChartReturn {
  return useMemo((): RaceChartReturn => {
    return RACES.reduce<RaceChartReturn>((allRaces, race) => {
      allRaces[race] = getStudentsByKeyValue(students, "race", race);
      return allRaces;
    }, {} as RaceChartReturn);
  }, [students]);
}
