import { FC, ReactNode } from "react";
import styled from "styled-components";
import { ReactComponent as emberexLogo } from "../images/EmberexLogo.svg";
import IconLink, { LinkOption, LogoColorOption } from "./IconLink";
export interface FooterProps {
  embLogoColor?: string;
  midContent?: ReactNode;
  linkColor?: LogoColorOption;
}

export const Footer: FC<FooterProps> = ({
  embLogoColor,
  midContent,
  linkColor,
  ...props
}) => (
  <div {...props}>
    <EmberexLogo color={embLogoColor} id="emberex-logo" />
    <ContentContainer id="footer-content">
      {midContent}
      <LinkCont midContent={!!midContent}>
        <IconLink to={LinkOption.EMBEREX} linkColor={linkColor} />
        <IconLink to={LinkOption.FACEBOOK} linkColor={linkColor} />
        <IconLink to={LinkOption.LINKEDIN} linkColor={linkColor} />
      </LinkCont>
    </ContentContainer>
  </div>
);

export default styled(Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const LinkCont = styled.div<{ midContent?: boolean }>`
  display: flex;
  padding: 0;
  margin-top: ${({ midContent }) => (midContent ? 0 : "20px")};
  > :nth-child(2) {
    margin: 0 20px;
  }
`;

const EmberexLogo = styled(emberexLogo)<{ color?: string }>`
  color: ${({ color }) => color ?? "#484848"};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
