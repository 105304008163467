import { FC, MouseEvent, useCallback, useMemo, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { getDatasetAtEvent, Chart } from "react-chartjs-2";
import Column from "@emberex/components/lib/Column";
import {
  ChartOptions,
  ChartData,
  Chart as ChartJs,
  RadialLinearScale,
  LinearScale,
  ArcElement,
  PolarAreaController,
  CategoryScale,
} from "chart.js";
import Row from "@emberex/components/lib/Row";
import Student from "../types/Student";
import StudentListPopover from "./StudentListPopover";

ChartJs.register(
  LinearScale,
  RadialLinearScale,
  ArcElement,
  PolarAreaController,
  CategoryScale
);

const defaultPolarAreaChartOptions: ChartOptions<"polarArea"> = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      ticks: {
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    r: {
      grid: {
        color: "black",
        z: 2,
      },
      ticks: {
        display: false,
      },
    },
  },
};

export interface PolarAreaChartData {
  label: string;
  students: readonly Student[];
  value: number;
  color: string;
}

export interface PolarAreaChartProps {
  title: string;
  values: ReadonlyArray<PolarAreaChartData>;
}

type PopoverData = null | { label: string; students: readonly Student[] };

export const PolarAreaChart: FC<PolarAreaChartProps> = ({
  values,
  title,
  ...rest
}) => {
  const chartRef = useRef<ChartJs<"polarArea">>(null);

  const data = useMemo(
    (): ChartData<"polarArea"> => ({
      datasets: [
        {
          data: values.map((v) => v.value),
          backgroundColor: values.map((v) => v.color),
          borderWidth: 0,
        },
      ],
    }),
    [values]
  );

  const maxTicks = useMemo(
    () => (Math.max(...values.map((v) => v.value)) > 60 ? 100 : 60),
    [values]
  );

  const ticks = useMemo(() => {
    const tickList: number[] = [];
    for (let i = 10; i <= maxTicks; i += 10) {
      tickList.push(i);
    }
    return tickList.reverse();
  }, [maxTicks]);

  // Popper requires useState instead of useRef
  // https://popper.js.org/react-popper/v2/#example
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(
    null
  );
  const [popoverData, setPopoverData] = useState<PopoverData>(null);
  const handleClosePopover = useCallback(() => setPopoverData(null), []);

  const handleChartClick = useCallback(
    (event: MouseEvent<HTMLCanvasElement>) => {
      if (!chartRef.current) {
        return;
      }

      const [dataSet] = getDatasetAtEvent(chartRef.current, event);
      if (!dataSet) {
        return;
      }
      const data = values[dataSet.index];
      setPopoverData(data);
    },
    [values]
  );

  return (
    <Column {...rest} ref={setPopoverAnchor}>
      <Chart
        type="polarArea"
        ref={chartRef}
        data={data}
        options={defaultPolarAreaChartOptions}
        width={300}
        height={300}
        onClick={handleChartClick}
      />
      <Ticks numTicks={ticks.length}>
        {ticks.map((tick) => (
          <Tick key={tick}>{tick}</Tick>
        ))}
      </Ticks>
      {popoverData && (
        <StudentListPopover
          chartTitle={title}
          chartDataName={popoverData.label}
          students={popoverData.students}
          anchorElement={popoverAnchor}
          onClose={handleClosePopover}
        >
          <Subheading>
            <StudentCount>{popoverData.students.length} </StudentCount>
            {popoverData.label.replace(/ Students?$/, "")} Students
          </Subheading>
        </StudentListPopover>
      )}
    </Column>
  );
};

export default styled(PolarAreaChart)``;

const Subheading = styled.div`
  font-size: 1rem;
  font-weight: 400;
`;

const StudentCount = styled.span`
  font-size: 2rem;
  font-weight: 300;
`;

const Tick = styled(Row)`
  height: 16px;
  width: 21px;
  border-radius: 7.5px;
  background-color: #000000;
  color: #ffffff;
  font-family: Muli;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const Ticks = styled(Column)<{ numTicks: number }>`
  position: absolute;
  left: 140px;
  top: -5px;
  ${Tick} + ${Tick} {
    margin-top: ${(props) => (props.numTicks < 10 ? 7.5 : 4.5)}px;
  }

  ${Tick} {
    ${(props) =>
      props.numTicks === 10 &&
      css`
        font-size: 0.625rem;
        height: 10px;
      `}
  }
`;
