import { useMemo } from "react";
import Student from "../../types/Student";
import { StudentDemographicsData } from "../../components/StudentDemographicCharts";
import { Gender } from "../../constants/Gender";
import { getStudentsByKeyValue } from "../../utils/getStudentsByKeyValue";

export default function useStudentDemographicsChart(
  students: readonly Student[]
): StudentDemographicsData {
  return useMemo((): StudentDemographicsData => {
    return {
      ellChartData: {
        ellStudents: getStudentsByKeyValue(students, "isEll"),
        nonEllStudents: getStudentsByKeyValue(students, "isEll", false),
      },
      genderChartData: {
        femaleStudents: getStudentsByKeyValue(
          students,
          "gender",
          Gender.FEMALE
        ),
        maleStudents: getStudentsByKeyValue(students, "gender", Gender.MALE),
        otherGenderStudents: getStudentsByKeyValue(
          students,
          "gender",
          Gender.OTHER
        ),
      },
      plan504ChartData: {
        hasPlanStudents: getStudentsByKeyValue(students, "has504Plan"),
        hasNoPlanStudents: getStudentsByKeyValue(students, "has504Plan", false),
      },
    };
  }, [students]);
}
