import { FC } from "react";
import styled from "styled-components";
import Overlay from "@emberex/components/lib/Overlay";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import IslandCard from "./IslandCard";
import { ReactComponent as emailIcon } from "../images/email_icon.svg";
import Footer from "./Footer";
import { LogoColorOption } from "./IconLink";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HOME,
  IMPORT_ISLAND,
  DASHBOARD_DISTRICT,
  BaseRoute,
} from "../constants/routes";
import { BreakPoint } from "../constants/BreakPoint";
import { useMenu } from "../context/menuContext";
import TitleLink from "./TitleLink";
import HamburgerButton from "./HamburgerButton";

export const BurgerModalMenu: FC = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { menuOpen, toggleMenu } = useMenu();

  const handleClick = (route: BaseRoute) => {
    if (pathname !== route) {
      navigate(route);
    }
    toggleMenu();
  };

  return !menuOpen ? null : (
    <Overlay {...props}>
      <BurgerButton menuOpen={menuOpen} onClick={toggleMenu} />
      <TitleContainer>
        <TitleLink
          option={HOME}
          to={HOME}
          onLinkClick={() => handleClick(HOME)}
        />
      </TitleContainer>
      <Main>
        <ContentContainer>
          <CardContainer>
            <IslandCard
              cardOption="import"
              onCardClick={() => handleClick(IMPORT_ISLAND)}
            />
            <IslandCard
              cardOption="dashboard"
              onCardClick={() => handleClick(DASHBOARD_DISTRICT)}
            />
          </CardContainer>
          <TextContainer>
            <h3>Dreaming of your own EDventure?</h3>
            <h4>Cool. Custom EdTech apps are our thing.</h4>
            <span>
              <EmailIcon />
              <a href="mailto:hello@emberex.com">hello@emberex.com</a>
            </span>
          </TextContainer>
        </ContentContainer>
        <Divider />
        <Footer
          embLogoColor="black"
          midContent={<MidContent>Let's talk.</MidContent>}
          linkColor={LogoColorOption.LIGHT_BLUE}
        />
      </Main>
    </Overlay>
  );
};

const BurgerButton = styled(HamburgerButton)`
  position: absolute;
  top: 39px;
  right: 58px;
  transition: all 0.8s ease;
  z-index: 1;
`;

const ContentContainer = styled(Row)``;

const Main = styled(Column)`
  position: relative;
  width: 100%;
  padding: 0 82px;
  margin-top: 101px;
`;

const CardContainer = styled(Row)``;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "Rubik";
  margin: 50px 0 0 30px;
  width: 400px;

  h3 {
    font-size: 44px;
    color: #d3ef58;
    margin: 0;
  }

  h4 {
    font-size: 30px;
    color: #adf1e8;
    margin: 10px 0 0 0;
    padding-right: 75px;
    font-weight: 400;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 20px;
    margin-left: 8px;
  }

  span {
    display: inline-flex;
    align-items: center;
    margin: 44px 0 40px 0;
    font-weight: 300;
  }
`;

const EmailIcon = styled(emailIcon)`
  fill: #b61f98;
  stroke: #333333;
  height: 22px;
  width: 22px;
  margin: 0;
`;

const Divider = styled.div`
  border: 1px solid black;
  margin: 80px 0 40px 0;
`;

const MidContent = styled.p`
  font-size: 30px;
  color: white;
  font-family: "Rubik";
  padding: 0;
  margin: 16px 0;
`;

const TitleContainer = styled(Row)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 22px;
`;

export default styled(BurgerModalMenu)`
  background-color: #333333;

  h2 {
    transition: all 0.8s ease;
  }

  ${CardContainer} {
    justify-content: center;
  }

  ${IslandCard} + ${IslandCard} {
    margin-left: 16px;
  }

  ${Footer} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 0;
  }

  @media (max-width: 1200px) {
    ${ContentContainer} {
      flex-direction: column;
    }
    ${IslandCard} + ${IslandCard} {
      margin-left: 20px;
    }

    ${TextContainer} {
      align-self: flex-start;
      width: 100%;
      margin: 0;
      margin-top: 48px;

      span {
        margin-bottom: 0;
      }
    }
  }

  @media ${BreakPoint.LARGE} {
    ${TitleContainer} {
      padding-top: 39px;

      h1 {
        font-size: 32px;
      }
    }

    ${Main} {
      padding: 0 26px;
      width: 100vw;
      margin-top: 60px;
    }

    ${Divider} {
      margin: 60px 0 36px 0;
    }

    ${BurgerButton} {
      right: 26px;
    }

    ${Footer} {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin-bottom: 60px;

      #emberex-logo {
        margin-right: 105px;
      }

      #footer-content {
        margin-left: 105px;
      }
    }

    ${MidContent} {
      margin: 0 0 16px 0;
    }
  }

  @media ${BreakPoint.MEDIUM} {
    ${TitleContainer} {
      padding-top: 39px;
    }

    ${Main} {
      padding: 0;
    }

    ${CardContainer} {
      flex-direction: column;
      align-items: center;
    }

    ${IslandCard} + ${IslandCard} {
      margin: 20px 0 0 0;
    }

    ${TextContainer} {
      margin-left: 20px;
    }

    ${Divider} {
      margin-left: 12px;
      margin-right: 12px;
    }

    h3 {
      width: 90%;
    }
  }

  @media (max-width: 550px) {
    ${TitleContainer} {
      padding: 48px 0 0 12px;
      justify-content: flex-start;
    }

    ${BurgerButton} {
      top: 50px;
    }
  }

  @media (max-width: 450px) {
    ${TitleLink} {
      width: 146px;
      h1 {
        font-size: 24px;
      }
    }

    ${BurgerButton} {
      top: 62px;
      right: 16px;
    }
  }

  @media ${BreakPoint.SMALL} {
    ${TitleContainer} {
      padding-top: 48px;
    }

    ${Main} {
      margin-top: 36px;
    }

    ${CardContainer} {
      flex-direction: column;
      margin: 0;
      align-items: center;

      div:first-child {
        margin-bottom: 10px;
      }
    }

    ${IslandCard} {
      margin: 0;
    }

    ${TextContainer} {
      margin: 24px 0 24px 12px;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 20px;
      margin: 10px 0 24px 0;
      font-weight: 300;
      width: 296px;
    }

    span {
      margin: 0;
    }

    ${Divider} {
      margin-bottom: 24px;
    }

    ${Footer} {
      flex-direction: column;
      margin-bottom: 60px;

      #emberex-logo,
      #footer-content {
        margin: 0;
      }
    }

    ${MidContent} {
      margin-top: 24px;
    }
  }
`;
