export function generateEncodedCsvString<T extends Record<string, unknown>[]>(
  data: T
): string | undefined {
  if (!data.length) {
    return;
  }

  const csvHeaderStr = `${Object.keys(data[0]).toString()}\n`;

  const csvRows = data
    .map(
      (row) =>
        Object.values(row)
          .map((value) => `${value}`)
          .toString() + "\n"
    )
    .join("")
    .trim();

  const universalBOM = "\uFEFF";
  const uriComponent = `${universalBOM}${csvHeaderStr + csvRows}`;
  return `data:text/csv; charset=utf-8, ${encodeURIComponent(uriComponent)}`;
}
