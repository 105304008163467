import { AnchorElement } from "../components/PopoverBase";

export function getVirtualElementInScrollingContainer({
  target,
  container,
  containerPadding = 0,
}: {
  /** The element on which to base the virtual element's bounding box */
  target: Element;
  /** An element which defines the vertical limits of the virtual element */
  container: Element;
  /** Pixels to subtract from the container's top and bottom bounds */
  containerPadding?: number;
}): AnchorElement {
  return {
    contextElement: target,
    getBoundingClientRect() {
      const containerRect = container.getBoundingClientRect();
      const chartRect = target.getBoundingClientRect();

      const { left, right, width } = chartRect;
      const top = Math.min(
        Math.max(containerRect.top, chartRect.top),
        containerRect.bottom - containerPadding
      );
      const bottom = Math.max(
        Math.min(containerRect.bottom, chartRect.bottom),
        containerRect.top + containerPadding
      );
      const height = bottom - top;

      return { left, right, top, bottom, width, height } as ClientRect;
    },
  };
}
