import { FC, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Column from "@emberex/components/lib/Column";
import DirectionButton from "../../../components/DirectionButton";
import { IMPORT_ISLAND, DASHBOARD_DISTRICT } from "../../../constants/routes";
import { BreakPoint } from "../../../constants/BreakPoint";
import { useUploadContext } from "../../../context/uploadContext";
import AnimatedIsland from "../../../components/AnimatedIsland";

const Complete: FC = (props) => {
  const { file } = useUploadContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!file) {
      navigate(IMPORT_ISLAND);
    }
  }, [file, navigate]);

  return (
    <Root {...props}>
      <Heading>Success!</Heading>
      <ButtonLink to={DASHBOARD_DISTRICT}>
        <DirectionButton direction="forward">
          <DistrictText>View Data in Dashboard District</DistrictText>
        </DirectionButton>
      </ButtonLink>
      <ButtonLink to={IMPORT_ISLAND}>
        <DirectionButton direction="backward">
          <IslandText>Return to Import Island</IslandText>
        </DirectionButton>
      </ButtonLink>
      <ImportIsland islandOption="import" />
      <DashboardDistrict islandOption="dashboard" />
    </Root>
  );
};

const ButtonLink = styled(Link)`
  text-decoration: none;
  z-index: 1;

  ${DirectionButton} {
    width: 272px;
    height: 64px;
    font-size: 16px;
  }
`;

const DistrictText = styled.span`
  width: 140px;
  line-height: 20px;
  font-weight: 200;
`;

const IslandText = styled(DistrictText)`
  width: 100px;
`;

const Heading = styled.h1`
  letter-spacing: 0;
  color: #4d4d4d;
  font-size: 3.125rem;
  font-weight: 400;
  line-height: 3.9375rem;
  text-align: center;
  margin: 235px 0 76px 0;
  transition: all 0.8s ease;
`;

const ImportIsland = styled(AnimatedIsland)`
  position: absolute;
  top: 63px;
  right: 57%;
  height: 908px;
  width: 1052px;
  transition: all 0.8s ease;
  transform: scale(1.3);
`;

const DashboardDistrict = styled(AnimatedIsland)`
  position: absolute;
  top: 87px;
  left: 65%;
  height: 672px;
  width: 883px;
  transition: all 0.8s ease;
  transform: scale(1.4);
`;

export default styled(Complete)`
  flex: 1;
  width: 100vw;
  height: 100%;
  align-items: center;

  ${ButtonLink} + ${ButtonLink} {
    margin-top: 20px;
  }

  @media ${BreakPoint.LARGE} {
    ${ImportIsland} {
      top: 192px;
      height: 653px;
      width: 757px;
      right: 68%;
    }
    ${DashboardDistrict} {
      height: 477px;
      top: 102px;
      left: 57%;
    }
    ${Heading} {
      margin: 140px 0 105px 0;
    }
  }

  @media ${BreakPoint.MEDIUM} {
    ${ImportIsland} {
      top: 370px;
      right: 50%;
    }
    ${DashboardDistrict} {
      top: 200px;
      left: 47%;
    }
    ${Heading} {
      margin: 200px 0 173px 0;
    }
  }

  @media ${BreakPoint.SMALL} {
    ${ImportIsland}, ${DashboardDistrict} {
      display: none;
    }
    ${Heading} {
      font-size: 32px;
      margin: 160px 0 120px 0;
    }
  }
`;

const Root = styled(Column)`
  &.slide-enter {
    transform: translate(50%, 0);
    z-index: 1;
  }

  &.slide-enter-active {
    transform: translate(0, 0);

    ${ImportIsland} {
      display: none;
    }

    transition: transform 1s ease;
  }

  &.slide-exit-active {
    display: none;
  }
`;
