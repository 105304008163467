import {
  createContext,
  useContext,
  useState,
  FC,
  useCallback,
  PropsWithChildren,
} from "react";

export interface MenuContextValue {
  menuOpen: boolean;
  toggleMenu: () => void;
}

const MenuContext = createContext<MenuContextValue | null>(null);

export const MenuContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setMenuOpen((open) => !open);
  }, []);

  return (
    <MenuContext.Provider value={{ menuOpen, toggleMenu }}>
      {children}
    </MenuContext.Provider>
  );
};

export function useMenu(): MenuContextValue {
  const contextValue = useContext(MenuContext);

  if (!contextValue) {
    throw new Error("Hook useMenu must be a child of MenuContext.Provider");
  }

  return contextValue;
}
