import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import Row from "@emberex/components/lib/Row";
import Student from "../../../types/Student";
import TableControls from "../../../components/TableControls";
import { BreakPoint } from "../../../constants/BreakPoint";
import { useUploadContext } from "../../../context/uploadContext";
import {
  IMPORT_ISLAND,
  IMPORT_ISLAND_UPLOAD_COMPLETE,
} from "../../../constants/routes";
import { useStudents } from "../../../context/studentContext";
import AnimatedIsland from "../../../components/AnimatedIsland";

const Import: FC<{ previousPath: string }> = ({ previousPath, ...rest }) => {
  const { file, saveFile } = useUploadContext();
  const { updateStudents } = useStudents();

  const navigate = useNavigate();

  const handleImportAbort = useCallback(() => {
    saveFile(null);
  }, [saveFile]);

  const handleImportSuccess = useCallback(
    (data: Student[]) => {
      updateStudents(data);
      navigate(IMPORT_ISLAND_UPLOAD_COMPLETE);
    },
    [updateStudents, navigate]
  );

  useEffect(() => {
    if (!file) {
      navigate(IMPORT_ISLAND);
    }
  }, [file, navigate]);

  const forwards = previousPath === IMPORT_ISLAND;

  return (
    <Root forwards={forwards} {...rest}>
      <TableControls
        file={file}
        onPreventUpload={handleImportAbort}
        onImportCancelled={handleImportAbort}
        onImportSuccess={handleImportSuccess}
      />
      <AnimatedIsland islandOption="import" />
    </Root>
  );
};

export default styled(Import)`
  flex: 1;
  width: 100vw;
  height: 100%;
  justify-content: flex-end;

  ${AnimatedIsland} {
    height: 908px;
    width: 1052px;
    position: absolute;
    top: 63px;
    right: 57%;
    transition: all 0.8s ease;
    transform: scale(1.3);
  }

  ${TableControls} {
    width: 52%;
    margin: 200px 58px 0 0;
    z-index: 1;
  }

  @media ${BreakPoint.LARGE} {
    ${AnimatedIsland} {
      height: 653px;
      width: 757px;
      top: 140px;
      right: 65%;
    }
    ${TableControls} {
      width: 60%;
      margin: 160px 26px 0 0;
    }
  }

  @media ${BreakPoint.MEDIUM} {
    justify-content: center;
    padding: 0 12px;

    ${TableControls} {
      width: 97%;
      margin: 160px 0 0 0;
    }
    ${AnimatedIsland} {
      top: 653px;
      right: 37%;
    }
  }

  @media ${BreakPoint.SMALL} {
    ${AnimatedIsland} {
      display: none;
    }
    ${TableControls} {
      button {
        width: 120px;
      }
    }
  }
`;

const Root = styled(Row)<{ forwards: boolean }>`
  &.slide-enter {
    transform: translate(75%, 0);
    z-index: 1;
  }

  &.slide-enter-active {
    transform: translate(0, 0);
    transition: transform 1s ease;
  }
  &.slide-exit-active {
    position: absolute;
    top: 0;
    left: 0;

    ${AnimatedIsland} {
      ${({ forwards }) =>
        !forwards &&
        css`
          display: none;
        `}
    }

    ${TableControls} {
      display: none;
    }
  }
`;
