import { toggleInArray } from "@emberex/array-utils";
import {
  createContext,
  useContext,
  useState,
  FC,
  PropsWithChildren,
} from "react";
import { Grade, GRADES } from "../constants/Grade";

export interface GradeContextValue {
  globalSelectedGrade: Grade | null;
  updateGlobalSelectedGrade: (updatedGrade: Grade | null) => void;
  filteredGrades: Grade[];
  updateFilteredGrades: (newGrade: Grade) => void;
}

const GradeContext = createContext<GradeContextValue | null>(null);

export const GradeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [globalSelectedGrade, setGlobalSelectedGrade] = useState<Grade | null>(
    null
  );
  const [filteredGrades, setFilteredGrades] = useState<Grade[]>(GRADES);

  return (
    <GradeContext.Provider
      value={{
        globalSelectedGrade,
        updateGlobalSelectedGrade: (updatedGrade: Grade | null) =>
          setGlobalSelectedGrade(updatedGrade),
        filteredGrades,
        updateFilteredGrades: (newGrade: Grade) =>
          setFilteredGrades(toggleInArray(filteredGrades, newGrade)),
      }}
    >
      {children}
    </GradeContext.Provider>
  );
};

export function useGrades(): GradeContextValue {
  const contextValue = useContext(GradeContext);

  if (!contextValue) {
    throw new Error("Hook useGrades must be a child of GradeContext.Provider");
  }

  return contextValue;
}
