export enum Race {
  AMERICAN_INDIAN_ALASKA_NATIVE = "American Indian / Alaska Native",
  ASIAN = "Asian",
  AFRICAN_AMERICAN = "Black or African American",
  CAUCASIAN = "White or Caucasian",
  HISPANIC = "Hispanic / Latino",
  PACIFIC_ISLANDER = "Native Hawaiian or Other Pacific Islander",
  TWO_OR_MORE = "Two or More Races",
}

export const RACES = Object.values(Race);

export const RaceAliases: Record<Race, string[]> = {
  [Race.AMERICAN_INDIAN_ALASKA_NATIVE]: ["AM7"],
  [Race.ASIAN]: ["AS7"],
  [Race.AFRICAN_AMERICAN]: ["BL7", "Black", "black"],
  [Race.CAUCASIAN]: ["WH7", "White", "white"],
  [Race.HISPANIC]: ["HI7"],
  [Race.PACIFIC_ISLANDER]: ["PI7", "Native Hawaiian", "native hawaiian"],
  [Race.TWO_OR_MORE]: ["MU7"],
};

export type RaceDistribution = Record<Race, number>;

// based off this source => Race/ethnicity + Time series + All available findings (fall 2020)
// https://nces.ed.gov/programs/coe/indicator/cge/racial-ethnic-enrollment
export const defaultRaceDistribution: RaceDistribution = {
  [Race.AMERICAN_INDIAN_ALASKA_NATIVE]: 1,
  [Race.ASIAN]: 5,
  [Race.AFRICAN_AMERICAN]: 16,
  [Race.CAUCASIAN]: 46,
  [Race.HISPANIC]: 28,
  [Race.PACIFIC_ISLANDER]: 0,
  [Race.TWO_OR_MORE]: 5,
};
