export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
}

export const GENDERS = Object.values(Gender);

export const genderAliases: Record<Gender, string[]> = {
  [Gender.MALE]: ["m"],
  [Gender.FEMALE]: ["f"],
  [Gender.OTHER]: [],
};

export type GenderDistribution = Record<Gender, number>;

// male/female distribution based of this stat
// https://www.collegesimply.com/colleges/oregon/university-of-oregon/students/
// other stat factored from resource below ( page 4 - paragraph 3). pulled percentage equally from both male/female
// https://www.oregon.gov/ode/students-and-family/equity/Documents/LGBTQ2SIA+%20Student%20Success%20Plan.pdf
export const defaultGenderDistributionPercentage: GenderDistribution = {
  [Gender.FEMALE]: 45,
  [Gender.MALE]: 35,
  [Gender.OTHER]: 20,
};
