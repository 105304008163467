import { FC, useCallback } from "react";
import Column from "@emberex/components/lib/Column";
import styled, { css } from "styled-components";
import { Grade, GRADES } from "../constants/Grade";
import PillButton from "./PillButton";
import useToggleState from "@emberex/react-utils/lib/useToggleState";
import TextButton from "@emberex/components/lib/TextButton";
import useOnClickOutside from "@emberex/react-utils/lib/useOnClickOutside";

export interface GradeDropdownProps {
  selected: Grade | null;
  onSelected(grade: Grade | null): void;
}

export const GradeDropdown: FC<GradeDropdownProps> = ({
  selected,
  onSelected,
  ...rest
}) => {
  const [open, toggleOpen, setOpen] = useToggleState(false);
  const handleSelect = useCallback(
    (grade: Grade | null) => {
      // Allow the selected grade to clear if its clicked again.
      onSelected(grade === selected ? null : grade);
      setOpen(false);
    },
    [onSelected, setOpen, selected]
  );

  const handleClickOutside = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const rootRef = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  return (
    <Column ref={rootRef} {...rest}>
      <PillButton onClick={toggleOpen}>{selected ?? "All Grades"}</PillButton>
      {open && (
        <Dropdown>
          {selected && (
            <GradeSelectButton
              onClick={() => handleSelect(null)}
              selected={false}
            >
              All Grades
            </GradeSelectButton>
          )}
          {GRADES.map((grade) => (
            <GradeSelectButton
              key={grade}
              onClick={() => handleSelect(grade)}
              selected={selected === grade}
            >
              {grade}
            </GradeSelectButton>
          ))}
        </Dropdown>
      )}
    </Column>
  );
};

export default styled(GradeDropdown)`
  ${PillButton} {
    font-weight: 300;
    background-color: #d3ef58;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.25rem;
    text-align: center;
    color: #000000;
    width: 100%;
    padding: 12px 0;
    z-index: 1;
  }
  justify-content: center;
  align-items: center;
  width: 200px;
`;

const Dropdown = styled(Column)`
  position: absolute;
  width: 78%;
  border-radius: 0 0 7px 7px;
  background-color: #adf1e8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: 43px;
  z-index: 1;
  padding: 0.75rem 0 0.875rem 0;
`;

const activeStyle = css`
  background-color: #b61f98;
  color: #ffffff;
`;

const GradeSelectButton = styled(TextButton)<{ selected: boolean }>`
  color: #333333;
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 1.5625rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  &:hover {
    ${activeStyle}
  }
  ${(props) => props.selected && activeStyle}
`;
