import { FC } from "react";
import Row from "@emberex/components/lib/Row";
import styled from "styled-components";
import { Grade, GRADES } from "../constants/Grade";
import ToggleButton from "./ToggleButton";

export interface GradeToggleButtonsProps {
  selected: ReadonlyArray<Grade>;
  onToggle(grade: Grade): void;
  disabled?: boolean;
}

export const GradeToggleButtons: FC<GradeToggleButtonsProps> = ({
  selected,
  onToggle,
  disabled,
  ...rest
}) => (
  <Row {...rest}>
    {GRADES.map((grade) => (
      <ToggleAndLabel key={grade}>
        <ToggleButton
          toggled={selected.includes(grade)}
          onToggle={() => onToggle(grade)}
          disabled={disabled}
        />
        <span>{grade}</span>
      </ToggleAndLabel>
    ))}
  </Row>
);

export default styled(GradeToggleButtons)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

const ToggleAndLabel = styled(Row)`
  align-items: center;
  color: #ffffff;
  font-family: Roboto;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 0.875rem;
  ${ToggleButton} {
    margin-right: 0.625rem;
  }
  padding: 0.3125rem 0;
`;
