import { MutableRefObject, useEffect } from "react";

export function useOnOutOfBounds(
  container: MutableRefObject<HTMLDivElement | null>,
  active: boolean,
  callback: () => void,
  element?: Element
) {
  useEffect(() => {
    const onOutOfBounds = () => {
      if (container.current && element) {
        const { top: topOfContainer, bottom: bottomOfContainer } =
          container.current.getBoundingClientRect();
        const { top: topOfElement, bottom: bottomOfElement } =
          element.getBoundingClientRect();
        if (
          topOfContainer > bottomOfElement ||
          bottomOfContainer < topOfElement
        ) {
          callback();
        }
      }
    };

    const currentContainer = container.current;

    if (active) {
      currentContainer?.addEventListener("scroll", onOutOfBounds);
      // remove listener if user navigates away from page
      return () => {
        currentContainer?.removeEventListener("scroll", onOutOfBounds);
      };
    } else if (currentContainer && !element) {
      // if user is still on page and popover is closed - remove scroll event listener
      currentContainer.removeEventListener("scroll", onOutOfBounds);
    }
  }, [container, active, element, callback]);
}
