import { ComponentPropsWithoutRef, FC } from "react";
import styled, { css } from "styled-components";
import forwardCaret from "../images/caret_green.png";
import backwardCaret from "../images/caret_dark_grey.png";
import TextButton from "@emberex/components/lib/TextButton";

export interface DirectionButtonProps
  extends ComponentPropsWithoutRef<"button"> {
  direction: "forward" | "backward";
}

const DirectionButton: FC<DirectionButtonProps> = ({ children, ...rest }) => (
  <TextButton {...rest}>
    <DirectionArrow direction={rest.direction} />
    {children}
  </TextButton>
);

export default styled(DirectionButton)<DirectionButtonProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  letter-spacing: 0;
  line-height: 2.1875rem;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: 3px solid #333333;
  border-radius: 58.5px;
  height: 110px;
  width: 400px;
  padding: 0 40px;

  ${({ direction }) =>
    direction === "forward"
      ? css`
          color: #adf1e8;
          background-color: #333333;
        `
      : css`
          color: #333333;
          background-color: #a0e7e0;
        `}
`;

const DirectionArrow = styled.img.attrs<DirectionButtonProps>(
  ({ direction }) => ({
    src: direction === "forward" ? forwardCaret : backwardCaret,
    alt: direction === "forward" ? "Go Forward" : "Go Back",
  })
)<DirectionButtonProps>`
  position: absolute;
  height: 32px;
  width: 32px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  ${({ direction }) =>
    direction === "forward"
      ? css`
          transform: rotate(90deg);
          right: 5px;
        `
      : css`
          transform: rotate(-90deg);
          left: 5px;
        `}
`;
