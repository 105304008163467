import { ParsedCSVRecord } from "@emberex/csv-import/lib/types";
import StudentPerformance from "../types/StudentPerformance";
import { generateEncodedCsvString } from "./generateEncodedCsvString";
import { getPercentAsLetterGrade } from "./getPercentAsLetterGrade";
import { createStudents } from "./studentFactory";

export default function downloadSample(): string | undefined {
  const parsedStudents = createStudents(2, { withoutOptions: true }).map(
    (student) => {
      const convertedPerformance = Object.entries(
        student.studentPerformance
      ).reduce(
        (acc, [key, val]) => {
          return {
            ...acc,
            [key]: getPercentAsLetterGrade(val),
          };
        },
        {
          overallGrade: 0,
          mathGrade: null,
          scienceGrade: null,
          historyGrade: null,
          spellingGrade: null,
          grammarGrade: null,
          writingGrade: null,
        } as StudentPerformance
      );

      const { studentPerformance, disability, isEll, ...rest } = student;

      return {
        ...rest,
        disabilities: disability ?? "",
        ell: isEll,
        ...convertedPerformance,
      } as ParsedCSVRecord;
    }
  );

  return generateEncodedCsvString(parsedStudents);
}
