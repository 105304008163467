import { useRef, useEffect } from "react";

export function usePreviousValue<T>(value: T): T {
  const prevValueRef = useRef(value);

  useEffect(() => {
    prevValueRef.current = value;
  }, [value]);

  return prevValueRef.current;
}
