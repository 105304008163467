import Student from "../../types/Student";
import useStudentGradeTotalsChart from "./useStudentGradeTotalsChart";
import useStudentDemographicsChart from "./useStudentDemographicsChart";
import useStudentRaceChart from "./useStudentRaceChart";
import useDisabilitiesChart from "./useDisabilitiesChart";
import usePerformersChart from "./usePerformersChart";
import useSubjectByGradeChart from "./useSubjectByGradeChart";
import { DashboardChartsData } from "../../components/DashboardCharts";

interface UseDashboardChartsParams {
  allStudents: ReadonlyArray<Student>;
  filteredStudents: ReadonlyArray<Student>;
}

export default function useDashboardCharts({
  allStudents,
  filteredStudents,
}: UseDashboardChartsParams): DashboardChartsData {
  return {
    gradeCounts: useStudentGradeTotalsChart(allStudents),
    demographicData: useStudentDemographicsChart(filteredStudents),
    raceChartData: useStudentRaceChart(filteredStudents),
    disabilityData: useDisabilitiesChart(filteredStudents),
    performanceChartData: usePerformersChart(filteredStudents),
    subjectByGradeData: useSubjectByGradeChart(filteredStudents),
  };
}
