import { useState, useCallback, useMemo, FC } from "react";
import styled, { css } from "styled-components";
import StudentSchema from "../import-schemas/StudentSchema";
import Importer from "@emberex/csv-import/lib/Importer";
import Dropdown from "./Dropdown";
import Button from "./Button";
import swal from "sweetalert";
import { Errors, SelectedRows } from "@emberex/csv-import/lib/types";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import { ariaHideable } from "../utils/hideable";
import importStudents from "../utils/importStudents";
import Student from "../types/Student";

export interface TableControlsProps {
  file: File | null;
  onPreventUpload(): void;
  onImportCancelled(): void;
  onImportSuccess(students: Student[]): void;
  useUploadTool?: boolean;
}

const TableControls: FC<TableControlsProps> = ({
  file,
  onPreventUpload,
  onImportCancelled,
  onImportSuccess,
  useUploadTool,
  ...rest
}) => {
  const [selectedRows, setSelectedRows] = useState<SelectedRows | null>(null);
  // Importer cell errors
  const [errors, setErrors] = useState<Errors | null>(null);

  // Handle selected rows
  const handleRowSelect = useCallback((rows: SelectedRows) => {
    setSelectedRows(rows);
  }, []);

  // Internal importer errors
  const handleImporterErrors = useCallback(
    (message: string) => {
      swal("Error", message || "Whoops! Something went wrong.", "error");
      onPreventUpload();
    },
    [onPreventUpload]
  );

  const handleImport = useCallback(() => {
    if (selectedRows && !selectedRows.hasErrors) {
      const { errors: importParseErrors, students } =
        importStudents(selectedRows);
      if (importParseErrors.length) {
        swal(
          "Error",
          importParseErrors
            .map((err) => `${err.row}, ${err.column}: ${err.message}`)
            .join("\r\n")
        );
        return;
      }
      onImportSuccess(students);
    }
  }, [onImportSuccess, selectedRows]);

  const errorCount = useMemo(() => {
    if (errors) {
      const { currentPage, otherPages } = errors;
      const { errors: currentPageErrors } = currentPage;
      return (
        currentPageErrors.length +
        otherPages.reduce((count, page) => count + page.errorCount, 0)
      );
    }
    return 0;
  }, [errors]);

  const hasErrors = errorCount > 0;

  const selectedRowsHaveAreValid =
    selectedRows && selectedRows.data.length > 0 && !selectedRows?.hasErrors;

  const importEnabled = selectedRowsHaveAreValid || !hasErrors;

  return (
    <Column {...rest}>
      <ErrorContainer {...ariaHideable(!hasErrors)}>
        <ErrorMessageAndDropdown>
          <MessageContainer>
            <PreviewHeader>Whoops!</PreviewHeader>
            <ErrorText>
              We found some issues with your data. Please navigate to the errors
              below and correct them to be able to import.
            </ErrorText>
          </MessageContainer>
          <Dropdown
            data={[]}
            errorsOnPage={errors?.currentPage}
            pageErrors={errors?.otherPages}
            placeholderOption={`${errorCount} error${
              errorCount !== 1 ? "s" : ""
            }`}
          />
        </ErrorMessageAndDropdown>
      </ErrorContainer>
      {file && (
        <RowContainerInline>
          <PreviewHeader>Data Preview</PreviewHeader>
        </RowContainerInline>
      )}
      <RowContainer>
        <ImporterContainer>
          <Importer
            useUploadTool={useUploadTool}
            jsonSchema={StudentSchema}
            onRowSelect={handleRowSelect}
            onCellErrors={setErrors}
            onError={handleImporterErrors}
            rowsPerPage={1000}
            file={file}
          />
        </ImporterContainer>
      </RowContainer>
      {file && (
        <ButtonContainer>
          <ImportLabel>Do you want to import this data?</ImportLabel>
          <Button
            className={importEnabled ? "dark" : "light"}
            onClick={handleImport}
          >
            Import
          </Button>
          <Button className="dark" onClick={onImportCancelled}>
            Cancel
          </Button>
        </ButtonContainer>
      )}
    </Column>
  );
};

export default styled(TableControls)`
  margin: 0;
  width: 600px;
  max-width: 100%;

  ${Dropdown} {
    font-size: 20px;
  }

  ${Button} {
    font-size: 16px;
    font-weight: 300;
  }

  table {
    border-color: #333333;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    position: relative;
    margin: -10px 0 0 -2px;
  }
  th {
    font-size: 12px;
    font-weight: bold;
    background-color: #333333;
    color: #adf1e8;
    padding: 15px;
    white-space: nowrap;
  }
  td {
    background-color: #333333;
    font-weight: 300;
    height: 30px;
    input {
      font-size: 11px;
      background-color: #333333;
      border: none;
      color: #adf1e8;
      font-weight: 300;
      font-style: italic;
    }
  }

  .cell-error {
    background-color: #b61f98;
    input {
      color: white;
    }
  }
  .dropdown-list {
    background-color: #adf1e8;
  }

  .dropdown-list-item {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    height: 10px;
    color: #333333;
  }
  .actions-button-group {
    padding: 20px 20px;
    justify-content: center;
    align-content: center;
  }
`;

const RowContainer = styled("div")`
  max-width: 100vw;
  margin-bottom: 20px;
`;

const ErrorContainer = styled(Row)<{ hide: boolean }>`
  margin-bottom: 20px;
  height: 225px;
  transition: all 0.5s ease;

  ${({ hide }) =>
    hide &&
    css`
      height: 0;
      opacity: 0;
    `}
`;

const MessageContainer = styled(Column)`
  width: 50%;
`;

const ErrorMessageAndDropdown = styled(Column)`
  width: 50%;

  ${Dropdown} {
    margin-top: 36px;
  }
  }
`;

const RowContainerInline = styled(RowContainer)`
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const PreviewHeader = styled("div")`
  width: 184px;
  color: #333333;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
`;

const ImporterContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .dropdown-list {
    margin: -2px 0 0 0;
  }
  .dropdown-list-item {
    height: unset;
    min-height: 10px;
  }

  .dropdown-caret {
    top: 10px;
  }

  .table-container {
    order: 0;
    position: relative;
    background-color: #333333;
    max-width: 100%;
    min-height: 182px;
    max-height: 300px;
    overflow: auto;
    border-radius: 16px;
    padding: 0;

    td {
      min-width: 150px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #adf1e8;
      outline: 0.5px solid slategrey;
    }

    scrollbar-color: #adf1e8 transparent;
    scrollbar-width: thin;
  }

  .pagination,
  button.table-delete {
    display: none;
  }

  .error-tooltip-message {
    border-radius: 16px;
    max-width: 150px;
  }
`;

const ErrorText = styled("div")`
  font-size: 16px;
  color: #333333;
  font-weight: 600;
`;

const ButtonContainer = styled("div")`
  margin-top: 30px;
  text-align: center;
  button {
    margin-bottom: 1rem;
  }

  .light {
    background-color: #b7b7b7;
    color: #333333;
  }

  .dark {
    background-color: #333333;
    color: #adf1e8;
  }
`;

const ImportLabel = styled("div")`
  color: #333333;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 18px;
`;
