import { FC } from "react";
import styled from "styled-components";
import Student from "../types/Student";
import formatName from "../utils/formatName";
import PopoverBase, { PopoverBaseProps } from "./PopoverBase";

export type { AnchorElement } from "./PopoverBase";

export interface StudentListPopoverProps
  extends Omit<PopoverBaseProps, "variant"> {
  chartTitle: string;
  chartDataName: string;
  students: readonly Student[];
}

export const StudentListPopover: FC<StudentListPopoverProps> = ({
  children,
  chartTitle,
  chartDataName,
  students,
  ...rest
}) => (
  <PopoverBase variant="dashboard" {...rest}>
    <Heading>
      <HeadingTitle>{chartTitle}</HeadingTitle> - {chartDataName}
    </Heading>
    {children}
    <StudentList>
      {students.map((student) => (
        <li key={student.id}>
          <StudentName>{formatName(student)}</StudentName>
          <StudentId>{student.id}</StudentId>
        </li>
      ))}
    </StudentList>
  </PopoverBase>
);

export default styled(StudentListPopover)``;

const Heading = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
`;

const HeadingTitle = styled.span`
  color: #d3ef58;
`;

const StudentList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    border-bottom: 1px solid #000;
    padding: 1rem 0;
  }
  li:last-child {
    border-bottom: none;
  }
`;

const StudentName = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: #adf1e8;
  line-height: 1.125rem;
`;

const StudentId = styled.div`
  font-size: 0.75rem;
  font-weight: 300;
  color: #fff;
  line-height: 1rem;
`;
