import styled from "styled-components";

export default styled.button`
  cursor: pointer;
  border: none;
  height: 50px;
  width: 178px;
  border-radius: 43px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 23px;
  margin-right: 16px;
  text-align: center;
`;
