import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  createFromConfig as am4coreCreateFromConfig,
  Sprite,
} from "@amcharts/amcharts4/core";
import { XYChart } from "@amcharts/amcharts4/charts";
import { Race, RACES } from "../constants/Race";
import Student from "../types/Student";
import DashboardPane from "./DashboardPane";
import Column from "@emberex/components/lib/Column";
import ChartTitle from "./ChartTitle";
import Row from "@emberex/components/lib/Row";
import ChartColorKey from "./ChartColorKey";
import StudentListPopover from "./StudentListPopover";

const dataMarkerColors: Record<string, string> = {
  [Race.AMERICAN_INDIAN_ALASKA_NATIVE]: "#D3EF58",
  [Race.ASIAN]: "#5CE5B8",
  [Race.AFRICAN_AMERICAN]: "#5CE5E5",
  [Race.CAUCASIAN]: "#5CB8E5",
  [Race.HISPANIC]: "#5C8AE5",
  [Race.PACIFIC_ISLANDER]: "#8A5CE5",
  [Race.TWO_OR_MORE]: "#B85CE5",
};

const colorList = Object.values(dataMarkerColors);

const colorGradient = ["#C4EE4A", ...colorList, "#A43DDE"];

const chartKeyColors = Object.entries(dataMarkerColors).map(
  ([race, color]) => ({ label: race, color })
);

const amchartsConfig = {
  xAxes: [
    {
      type: "CategoryAxis",
      title: {
        text: "Race",
        fill: "#fff",
        fontSize: "12px",
        fontWeight: 400,
        dy: 10,
      },
      // Start left edge 90% through "start" placeholder's segment
      startLocation: 0.9,
      endLocation: 0.1,
      renderer: {
        grid: { disabled: true },
        labels: {
          wrap: true,
          maxWidth: 150,
          textAlign: "middle",
          fill: "#fff",
          fontFamily: "Muli",
          fontSize: "14px",
          fontWeight: 400,
        },
        // Hide the first and last (placeholder) labels
        minLabelPosition: 0.01,
        maxLabelPosition: 0.99,
      },
      dataFields: {
        category: "label",
      },
    },
  ],
  yAxes: [
    {
      type: "ValueAxis",
      title: {
        text: "# of students",
        fill: "#fff",
        fontSize: "12px",
        fontWeight: 400,
        rotation: 90,
        dx: -15,
      },
      // Pad min/max values by 20%
      extraMin: 0.2,
      extraMax: 0.2,
      renderer: {
        grid: {
          stroke: "#000",
          strokeOpacity: 1,
          strokeWidth: 1,
        },
        baseGrid: {
          disabled: true, // Hide the 0 line
        },
        labels: {
          fill: "#fff",
          fontFamily: "Muli",
          fontSize: "14px",
          fontWeight: 400,
          adapter: {
            hidden: (
              _hidden: boolean,
              { dataItem: { value } }: { dataItem: { value?: number } }
            ) => {
              const isPositiveInteger =
                value !== undefined && value >= 0 && value % 1 === 0;
              return !isPositiveInteger;
            },
          },
        },
      },
      dataFields: {
        value: "value",
      },
    },
  ],
  series: [
    {
      type: "LineSeries",
      smoothing: "monotoneX",
      dataFields: {
        valueY: "value",
        categoryX: "label",
      },
      fillOpacity: 1,
      fill: {
        type: "LinearGradient",
        stops: colorGradient.map((color) => ({ color })),
      },
      bullets: [
        {
          type: "CircleBullet",
          circle: {
            radius: 24,
            stroke: "#262626",
            strokeWidth: 2,
          },
          propertyFields: {
            fill: "bullet",
            hidden: "hidden",
            readerHidden: "hidden",
          },
        },
        {
          type: "LabelBullet",
          label: {
            text: "{value}",
            fontFamily: "Muli",
            fontSize: "24px",
            fontWeight: 300,
            textAlign: "center",
          },
          propertyFields: {
            hidden: "hidden",
            readerHidden: "hidden",
          },
        },
      ],
      // Save some CPU cycles
      layout: "none",
      simplifiedProcessing: true,
    },
  ],
};

type PopoverData = null | {
  anchor: SVGGElement;
  label: string;
  students: readonly Student[];
};

export interface RaceAreaChartProps {
  values: Record<Race, readonly Student[]>;
}

export const RaceAreaChart: FC<RaceAreaChartProps> = ({ values, ...rest }) => {
  // Sort the data so that it's in the correct left->right order based on the
  // position in the race list
  const sortedValues = useMemo(() => {
    return Object.entries(values).sort((a, b) => {
      return RACES.indexOf(a[0] as Race) - RACES.indexOf(b[0] as Race);
    });
  }, [values]);

  const dataPoints = useMemo(
    () =>
      sortedValues.map(([race, students], idx) => ({
        label: race,
        value: students.length,
        bullet: colorList[idx],
        hidden: false,
      })),
    [sortedValues]
  );

  const totalRepresentedRaces = useMemo(
    () => dataPoints.filter(({ value }) => value > 0).length,
    [dataPoints]
  );

  const chartData = useMemo(() => {
    const startPadding = Math.max(0, dataPoints[0].value - 5);
    const lastValue = dataPoints[dataPoints.length - 1].value;
    const endPadding = lastValue === 0 ? 0 : lastValue + 5;
    const ignoredColor = "#000";

    // Add hidden start and end points to add padding in the graph
    return [
      {
        label: "start",
        value: startPadding,
        bullet: ignoredColor,
        hidden: true,
      },
      ...dataPoints,
      {
        label: "end",
        value: endPadding,
        bullet: ignoredColor,
        hidden: true,
      },
    ];
  }, [dataPoints]);

  const [popoverData, setPopoverData] = useState<PopoverData>(null);
  const handleClosePopover = useCallback(() => setPopoverData(null), []);

  const handleBulletClick = useCallback(
    (bullet: Sprite) => {
      bullet.events.on("hit", (event) => {
        const { dataItem } = event.target;

        if (!dataItem) {
          throw new Error("Could not read dataItem from bullet click");
        }

        // Offset index to account for hidden start bullet
        const [race, students] = sortedValues[dataItem.index - 1];
        // Always reference CircleBullet, even if LabelBullet was clicked
        const anchor = dataItem.sprites[0].dom;

        setPopoverData({ anchor, label: race, students });
      });
    },
    [sortedValues]
  );

  const chartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!chartRef.current) {
      throw new Error("chartRef was not initialized");
    }

    const chart = am4coreCreateFromConfig(
      { ...amchartsConfig, data: chartData },
      chartRef.current,
      XYChart
    ) as XYChart;

    chart.series.values[0].bullets.each(handleBulletClick);

    return () => chart.dispose();
  }, [chartData, handleBulletClick]);

  return (
    <Root {...rest}>
      <ChartHead>
        <Column>
          <ChartTitle>Race</ChartTitle>
          <ChartRepresentation>
            <Row>{totalRepresentedRaces}</Row>
            <Row>Total Races</Row>
          </ChartRepresentation>
        </Column>
        <ChartColorKey colors={chartKeyColors} />
      </ChartHead>
      <Chart ref={chartRef} />
      {popoverData && (
        <StudentListPopover
          chartTitle="Race"
          chartDataName={popoverData.label}
          students={popoverData.students}
          anchorElement={popoverData.anchor}
          onClose={handleClosePopover}
        />
      )}
    </Root>
  );
};

export default styled(RaceAreaChart)``;

const Root = styled(DashboardPane)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 100%;
`;

const ChartHead = styled(Row)`
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  ${ChartColorKey} {
    max-width: 620px;
    flex-wrap: wrap;
    line-height: 1.5rem;
  }
`;

const ChartRepresentation = styled(Row)`
  color: #ffffff;
  font-family: Muli;
  font-size: 1rem;
  line-height: 1.25rem;
  align-items: baseline;
  > :first-child {
    color: #ffffff;
    font-weight: 300;
    font-size: 2.875rem;
    line-height: 3.625rem;
    margin-right: 0.5rem;
  }
`;

const Chart = styled(Column)`
  width: 100%;
  height: 350px;
  align-items: center;
`;
