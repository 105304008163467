import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import AnimatedIsland from "./AnimatedIsland";
import Row from "@emberex/components/lib/Row";

const ImportDashboardIslands: FC<PropsWithChildren> = ({
  children,
  ...rest
}) => (
  <Row {...rest}>
    <AnimatedIsland islandOption="both" />
    {children}
  </Row>
);

export default styled(ImportDashboardIslands)`
  ${AnimatedIsland} {
    transform: scale(1.3);
    width: 100%;
  }
`;
