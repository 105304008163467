export enum LetterGrade {
  APlus = "A+",
  A = "A",
  AMinus = "A-",
  BPlus = "B+",
  B = "B",
  BMinus = "B-",
  CPlus = "C+",
  C = "C",
  CMinus = "C-",
  D = "D",
  F = "F",
}

export const LETTER_GRADES = Object.values(LetterGrade);
