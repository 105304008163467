import { FC, PropsWithChildren } from "react";
import { MenuContextProvider } from "./menuContext";
import { StudentContextProvider } from "./studentContext";
import { UploadContextProvider } from "./uploadContext";
import { GradeContextProvider } from "./gradeContext";

export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => (
  <MenuContextProvider>
    <StudentContextProvider>
      <GradeContextProvider>
        <UploadContextProvider>{children}</UploadContextProvider>
      </GradeContextProvider>
    </StudentContextProvider>
  </MenuContextProvider>
);
