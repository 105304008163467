import { useEffect } from "react";

export const useEventListener = (
  attachmentPoint: Document | Window | MediaQueryList,
  eventKey: keyof DocumentEventMap | keyof WindowEventMap,
  eventListener: EventListenerOrEventListenerObject
) => {
  useEffect(() => {
    attachmentPoint.addEventListener(eventKey, eventListener);
    return () => attachmentPoint.removeEventListener(eventKey, eventListener);
  }, [attachmentPoint, eventKey, eventListener]);
};
