import { LetterGrade, LETTER_GRADES } from "../constants/LetterGrade";

/**
 * A performance grade can be a letter grade or a number (gpa, percentage)
 */
export type PerformanceGrade = LetterGrade | number;

export function isNumericGrade(grade: PerformanceGrade): grade is number {
  return typeof grade === "number";
}

export function isLetterGrade(grade: PerformanceGrade): grade is LetterGrade {
  return typeof grade === "string" && LETTER_GRADES.includes(grade);
}
