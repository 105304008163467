import { ComponentPropsWithoutRef, FC, useMemo } from "react";
import styled, { css, keyframes } from "styled-components";
import Student from "../types/Student";
import StudentPerformance from "../types/StudentPerformance";
import studentIcon from "../images/student_iconWhite.png";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import formatName from "../utils/formatName";
import { isNumericGrade } from "../types/PerformanceGrade";
import getGradeAsPercent from "../utils/getGradeAsPercent";

const gradientSteps = [
  "#D3EF58",
  "#5CE5B8",
  "#5CE5E5",
  "#5CB8E5",
  "#5C8AE5",
  "#8A5CE5",
  "#B85CE5",
];

export type StudentChartData = Pick<
  Student,
  "id" | "firstName" | "lastName" | "gradeLevel"
> & { studentPerformance: Pick<StudentPerformance, "overallGrade"> };

export interface StudentOverallScoreChartProps
  extends ComponentPropsWithoutRef<"div"> {
  student: StudentChartData;
  animationOffset?: number;
}

export const StudentOverallScoreChart: FC<StudentOverallScoreChartProps> = ({
  student,
  animationOffset = 0,
  ...rest
}) => {
  const { gradeLevel, studentPerformance } = student;
  const { overallGrade } = studentPerformance;
  const overallPercent = useMemo(
    (): number => getGradeAsPercent(overallGrade),
    [overallGrade]
  );

  return (
    <Row {...rest}>
      <StudentNameAndGraph>
        <StudentNameAndIcon>
          <img src={studentIcon} alt="Student" />
          <span>{formatName(student)}</span>
        </StudentNameAndIcon>
        <GraphBarContainer>
          <GraphBar
            overallPercent={overallPercent}
            offsetAnimation={animationOffset}
          />
        </GraphBarContainer>
        <StudentGradeLevel>{gradeLevel}</StudentGradeLevel>
      </StudentNameAndGraph>
      <OverallGrade>
        {isNumericGrade(overallGrade) ? overallGrade.toFixed(0) : overallGrade}
      </OverallGrade>
    </Row>
  );
};

export default styled(StudentOverallScoreChart)`
  color: #ffffff;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StudentNameAndIcon = styled(Row)`
  img {
    height: 18px;
    width: 16px;
    margin-right: 0.75rem;
  }
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  align-items: baseline;
`;

const StudentNameAndGraph = styled(Column)`
  width: 100%;
  height: 60px;
  justify-content: space-between;
`;

const OverallGrade = styled(Column)`
  font-family: "Muli";
  font-weight: 300;
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 2.5rem;
  margin-left: 1.5rem;
  height: 100%;
  padding-top: 6px;
`;

const animateWidth = (props: { overallPercent: number }) => keyframes`
  from {
    width: 0%;
  }
  to {
    width: ${props.overallPercent}%;
  }
`;

const createGradient = (props: { overallPercent: number }) => {
  const { overallPercent } = props;
  // Get the % each step will be. This can be a constant, but it'll make adding colors easier in the future
  const gradientStepPercents = (1 / gradientSteps.length) * 100;
  const maxColorIdx = Math.ceil(overallPercent / gradientStepPercents);
  const gradient = [...gradientSteps]
    // Reversed to map darker to lighter
    .reverse()
    .slice(0, Math.min(maxColorIdx, gradientSteps.length));
  const gradientStepDefinition = gradient
    // Reversed for gradient to start with the dark
    .reverse()
    .map((color, idx) => `${color} ${(idx / gradient.length) * 100}%`)
    .join(", ");

  return css`
    background: linear-gradient(270deg, ${gradientStepDefinition});
  `;
};

const GraphBar = styled(Row)<{
  overallPercent: number;
  offsetAnimation: number;
}>`
  width: 0;
  animation: ${animateWidth} 1s both;
  animation-delay: ${(props) => props.offsetAnimation * 0.2}s;
  height: 100%;
  // In case its not supported.
  background: #5cb8e5;
  // Performance note: this starts slowing down with a lot of students.
  ${createGradient}
`;

const GraphBarContainer = styled(Column)`
  width: 100%;
  height: 10px;
  background-color: #000000;
`;

const StudentGradeLevel = styled(Column)`
  font-family: "Muli";
  font-weight: 300;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 0.9375rem;
`;
