export const HOME = "/";
export const IMPORT_ISLAND = "/import-island";
export const DASHBOARD_DISTRICT = "/dashboard-district";

export const IMPORT_ISLAND_UPLOAD = `${IMPORT_ISLAND}/import` as const;
export const IMPORT_ISLAND_UPLOAD_COMPLETE =
  `${IMPORT_ISLAND}/import-complete` as const;

export type BaseRoute =
  | typeof HOME
  | typeof IMPORT_ISLAND
  | typeof DASHBOARD_DISTRICT;

export type SubRoute =
  | typeof IMPORT_ISLAND_UPLOAD
  | typeof IMPORT_ISLAND_UPLOAD_COMPLETE;
