import { useState, useCallback, useRef, FC } from "react";
import styled from "styled-components";
import {
  ErrorsOnPage,
  PageErrorItem,
  PageErrors,
} from "@emberex/csv-import/lib/types";
import Column from "@emberex/components/lib/Column";
import purpleCaretSrc from "../images/purple-caret.png";
import caretSrc from "../images/caret_green.png";
import useOutsideClick from "../hooks/useOutsideClick";

export interface DropdownProps {
  label?: string;
  data: string[];
  errorsOnPage?: ErrorsOnPage;
  pageErrors?: PageErrors[];
  onChange?(value: string): void;
  placeholderOption?: string;
}

export const Dropdown: FC<DropdownProps> = ({
  label,
  data,
  errorsOnPage,
  pageErrors,
  onChange,
  placeholderOption = "None Selected",
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>();
  const [selectedError, setSelectedError] = useState<PageErrorItem | null>(
    null
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    if (open) handleDropdownToggle();
  });

  const handleDropdownToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleDropdownSelection = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value);
      }
      setSelectedOption(value);
    },
    [onChange]
  );

  const handleErrorDropdownSelection = useCallback(
    (cellObj: PageErrorItem) => {
      const { rowIndex, message, cellRef } = cellObj;
      setSelectedOption(`(${rowIndex}) ${message}`);
      setSelectedError(cellObj);
      if (cellRef?.current) {
        cellRef.current.focus();
        cellRef.current.click();
      }
      setOpen(!open);
    },
    [open]
  );

  const handlePageErrorSelection = useCallback(
    (pageErrorObj: PageErrors) => {
      pageErrorObj.focusPage();
      setSelectedOption(placeholderOption);
    },
    [placeholderOption]
  );

  return (
    <Column {...rest}>
      <Label>{label}</Label>
      <DropdownContainer
        ref={dropdownRef}
        onClick={handleDropdownToggle}
        open={open}
      >
        <Selected>{selectedOption ?? placeholderOption}</Selected>
        <CaretContainer open={open} onClick={handleDropdownToggle}>
          <Caret
            src={errorsOnPage ? purpleCaretSrc : caretSrc}
            alt="Dropdown Caret"
          />
        </CaretContainer>
      </DropdownContainer>
      {open && (
        <OptionsContainer>
          <Options>
            {errorsOnPage
              ? errorsOnPage.errors.map((cellObj) => {
                  const { message, rowIndex, columnId } = cellObj;
                  return (
                    <OptionItem
                      title={`[row: ${rowIndex}, column: ${columnId}]: ${message}`}
                      key={`${rowIndex}${columnId}`}
                      active={
                        selectedError
                          ? selectedError.rowIndex === rowIndex
                          : false
                      }
                      onClick={() => {
                        handleErrorDropdownSelection(cellObj);
                      }}
                    >
                      {`[${rowIndex}] ${message}`}
                    </OptionItem>
                  );
                })
              : data.map((option, key) => {
                  return (
                    <OptionItem
                      key={key}
                      active={selectedOption === option}
                      onClick={() => handleDropdownSelection(option)}
                    >
                      {option}
                    </OptionItem>
                  );
                })}

            {pageErrors ? <OptionsDivider key={"divider"} /> : ""}
            {pageErrors?.map((pageErrorObj: PageErrors) => {
              return (
                <OptionItem
                  key={pageErrorObj.page}
                  active={false}
                  onClick={() => handlePageErrorSelection(pageErrorObj)}
                >
                  <b>{`Page ${pageErrorObj.page + 1}`}</b>
                  {`: ${pageErrorObj.errorCount} Error(s)`}
                </OptionItem>
              );
            })}
          </Options>
        </OptionsContainer>
      )}
    </Column>
  );
};

export default styled(Dropdown)`
  font-size: 28px;
`;

const Label = styled("div")`
  margin: 0 auto 6px 0;
  color: #333333;
  font-size: 20px;
  font-weight: 600;
`;

const DropdownContainer = styled("div")<{ open?: boolean }>`
  width: 100%;
  height: 48px;
  border-radius: 43px;
  background-color: #333333;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: ${({ open }) => (open ? 2 : 0)};
`;

const Selected = styled("div")`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 300;
  color: #adf1e8;
  margin-left: 33px;
  font-size: 18px;
`;

const CaretContainer = styled("div")<{ open: boolean }>`
  margin: 0 20px 0 auto;
  transition-duration: 0.4s;
  transform: ${(props) => (props.open ? 0 : "rotate(180deg);")};
  height: 100%;
  display: flex;
  align-items: center;
`;

const Caret = styled("img")`
  width: 28px;
`;

const OptionsContainer = styled(Column)`
  position: absolute;
  top: 95%;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateX(-7px);
  transition-duration: 0.4s;
  background-color: #adf1e8;
  z-index: 1;
`;

const Options = styled("ul")`
  position: absolute;
  margin: 0;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 20px;
  border-radius: 0 0 7px 7px;
  width: 100%;
  background-color: #adf1e8;
  color: #333333;
  text-align: left;
  list-style-type: none;
  padding-top: 14px;
  padding-left: 7px;
  padding-right: 6px;
  :last-child {
    padding-bottom: 12px;
  }
  max-height: 250px;
  overflow-y: auto;
`;

const OptionsDivider = styled("hr")`
  border: 0.2px solid #333333;
  width: 100%;
`;
const OptionItem = styled("li")<{ active: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${(props) => (props.active ? "#333333;" : "")};
  color: ${(props) => (props.active ? "#adf1e8;" : "#333333")};
  padding: 5px 5px;
  padding-left: 18px;

  :hover {
    background-color: #333333;
    color: #adf1e8;
  }
`;
