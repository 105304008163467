import random from "lodash.random";
import times from "lodash.times";
import faker from "faker";
import { v4 as uuid } from "uuid";
import Student from "../types/Student";
import StudentPerformance from "../types/StudentPerformance";
import {
  Disability,
  defaultDisabalityDistribution,
  DISABILITIES,
  DisabilityDistribution,
  studentsWithDisabilityPercentage,
} from "../constants/Disability";
import sample from "lodash.sample";
import {
  Gender,
  defaultGenderDistributionPercentage,
  GENDERS,
  GenderDistribution,
} from "../constants/Gender";
import { Grade, GRADES } from "../constants/Grade";
import {
  Race,
  defaultRaceDistribution,
  RaceDistribution,
  RACES,
} from "../constants/Race";

type SubjectGrades = Omit<StudentPerformance, "overallGrade">;

const passingGradeLevels = [
  { min: 60, max: 70 },
  { min: 70, max: 80 },
  { min: 80, max: 90 },
  { min: 90, max: 101 },
];

interface CreatePerformanceDataProps {
  withFailingGrade?: boolean;
}

export function createPerformanceData({
  withFailingGrade = false,
}: CreatePerformanceDataProps = {}): StudentPerformance {
  // This will help spread students performance out, so that there is a nice mix
  // of high and low performers

  const { min, max } = sample(passingGradeLevels) ?? passingGradeLevels[0];
  const subjectGrades: SubjectGrades = {
    grammarGrade: random(min, max),
    historyGrade: random(min, max),
    mathGrade: random(min, max),
    scienceGrade: random(min, max),
    spellingGrade: random(min, max),
    writingGrade: random(min, max),
  };

  const subjectKeys = Object.keys(subjectGrades);
  const randomFailingSubjectKey = sample(subjectKeys) ?? subjectKeys[0];

  const failingSubjectGrade = {
    [randomFailingSubjectKey]: random(0, 59),
  };

  const grades = {
    ...subjectGrades,
    ...(withFailingGrade && failingSubjectGrade),
  };

  // Give the overall grade a realistic value based on the subject grades
  const overallGrade = Object.values(grades).reduce(
    (overall: number, current) => overall + (current as number),
    0
  );

  return {
    ...grades,
    overallGrade: (overallGrade / 600) * 100,
  };
}

function randomBoolean(): boolean {
  return random(0, 100) % 2 === 0;
}
interface CreateStudentProps extends CreatePerformanceDataProps {
  gender?: Gender;
  race?: Race;
  disability?: Disability | null;
}
/**
 * Create a generated student.
 */
export function createStudent({
  withFailingGrade = false,
  gender = sample(GENDERS) ?? Gender.OTHER,
  race = sample(RACES) ?? Race.TWO_OR_MORE,
  disability = null,
}: CreateStudentProps = {}): Student {
  return {
    id: uuid(),
    firstName: faker.name.firstName(gender === Gender.FEMALE ? 1 : 0),
    lastName: faker.name.lastName(gender === Gender.FEMALE ? 1 : 0),
    disability,
    gender,
    gradeLevel: sample(GRADES) ?? Grade.KINDERGARTEN,
    has504Plan: randomBoolean(),
    isEll: randomBoolean(),
    race,
    studentPerformance: createPerformanceData({ withFailingGrade }),
  };
}

type CreateStudentOptions = {
  withoutOptions?: boolean;
  failurePercentage?: number;
  genderDistributionPercentage?: GenderDistribution;
  raceDistribution?: RaceDistribution;
  disabilityPercentage?: number;
  disabilityDistribution?: DisabilityDistribution;
};

export const createStudents = (
  count = 100,
  {
    withoutOptions = false,
    failurePercentage = 35,
    genderDistributionPercentage = defaultGenderDistributionPercentage,
    raceDistribution = defaultRaceDistribution,
    disabilityPercentage = studentsWithDisabilityPercentage,
    disabilityDistribution = defaultDisabalityDistribution,
  }: CreateStudentOptions = {}
): Student[] => {
  if (withoutOptions) {
    return times(count, () => createStudent());
  }
  return GENDERS.flatMap((gender) => {
    const genderPercentage = genderDistributionPercentage[gender];
    const studentsOfParticularGender = Math.round(
      count * (genderPercentage / 100)
    );

    // distribute race among genders
    return RACES.flatMap((race) => {
      const racePercentage = raceDistribution[race];
      if (!racePercentage) {
        return [];
      }

      const studentCountOfParticularRace = Math.round(
        studentsOfParticularGender * (racePercentage / 100)
      );

      // distribute disabilities among race & gender
      const studentCountWithDisabilities = Math.floor(
        studentCountOfParticularRace * (disabilityPercentage / 100)
      );

      const studentsWithoutDisabilities =
        studentCountOfParticularRace - studentCountWithDisabilities;

      // distribute failure percentage for each gender & race
      const studentsWithFailingSubject = Math.round(
        studentsWithoutDisabilities * (failurePercentage / 100)
      );

      const abledStudents = times(studentsWithoutDisabilities, (index) => {
        const withFailingGrade = index < studentsWithFailingSubject;
        return createStudent({ gender, race, withFailingGrade });
      });

      // going to leave out the withFailingGrade stat from the disabled category.
      const disabledStudents = DISABILITIES.flatMap((disability) => {
        const disabilityPercentage = disabilityDistribution[disability];
        if (!disabilityPercentage) {
          return [];
        }

        const studentsWithParticularDisability = Math.round(
          studentCountWithDisabilities * (disabilityPercentage / 100)
        );

        return times(studentsWithParticularDisability, () =>
          createStudent({ gender, race, disability })
        );
      });

      return [...abledStudents, ...disabledStudents];
    });
  });
};

export function createStudentsWithProperty<T extends keyof Student>(
  count: number,
  property: T,
  value: Student[T]
): Student[] {
  return createStudents(count).map((student) => ({
    ...student,
    [property]: value,
  }));
}
