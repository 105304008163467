import { FC } from "react";
import styled, { css } from "styled-components";
import TextButton from "@emberex/components/lib/TextButton";

export interface ToggleButtonProps {
  toggled: boolean;
  onToggle(): void;
  disabled?: boolean;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  toggled,
  onToggle,
  disabled,
  ...rest
}) => (
  <Root onClick={onToggle} disabled={disabled} {...rest}>
    <Slider toggled={toggled} disabled={disabled} />
  </Root>
);

export default styled(ToggleButton)``;

const Root = styled(TextButton)`
  height: 20px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const toggledStyle = css`
  background-color: #d3ef58;
  &::before {
    transform: translateX(23px);
  }
`;

const Slider = styled.span<{ toggled?: boolean; disabled?: boolean }>`
  height: 18px;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  transition: 0.4s;
  border-radius: 10px;
  border: 2px solid transparent;
  &::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    transition: 0.4s;
    border-radius: 50%;
    background-color: #4d4d4d;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    border: 1px solid #4d4d4d;
  }
  ${(props) => props.toggled && toggledStyle}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}
`;
