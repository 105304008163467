import { ComponentPropsWithoutRef, FC } from "react";
import styled from "styled-components";
import { ReactComponent as DashDistrict } from "../images/DashDistrict.svg";
import { ReactComponent as ImportIsland } from "../images/ImportIsland.svg";
import { IslandOption } from "../types/IslandOption";

export interface IslandLinkProps extends ComponentPropsWithoutRef<"button"> {
  option: Exclude<IslandOption, "both">;
}

const IslandLink: FC<IslandLinkProps> = ({ option, ...rest }) => (
  <button {...rest}>
    {option === "import" ? <ImportIsland /> : <DashDistrict />}
  </button>
);

export default styled(IslandLink)`
  border: none;
  background: none;
  display: inline-flex;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`;
