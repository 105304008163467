import { useEffect } from "react";

interface UseTimeoutParams {
  /**
   * A function to call on a timeout
   */
  timeoutFn(): void;

  /**
   * The amount of time to wait to call the function.
   * Default 1000 milliseconds
   */
  timeoutMillis?: number;
  /**
   * Disable the function from being called after the timeout.
   * Default false
   */
  disabled?: boolean;
}

export function useTimeout({
  timeoutFn,
  timeoutMillis = 1000,
  disabled = false,
}: UseTimeoutParams) {
  useEffect(() => {
    const timeout = () => {
      if (disabled) {
        return;
      }
      timeoutFn();
    };
    const timeoutId = window.setTimeout(timeout, timeoutMillis);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutFn, timeoutMillis, disabled]);
}
