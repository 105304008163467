import { useEffect } from "react";

const siteName = "Emberex Edventure";

export function useDocumentTitle(title: string) {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = title.trim() === "" ? siteName : `${siteName}: ${title}`;
    return () => {
      document.title = previousTitle;
    };
  }, [title]);
}
