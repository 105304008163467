import { LetterGrade } from "../constants/LetterGrade";

export const getPercentAsLetterGrade = (percent: number): LetterGrade => {
  if (percent >= 97) {
    return LetterGrade.APlus;
  }
  if (percent >= 93 && percent < 97) {
    return LetterGrade.A;
  }
  if (percent >= 90 && percent < 93) {
    return LetterGrade.AMinus;
  }
  if (percent >= 87 && percent < 90) {
    return LetterGrade.BPlus;
  }
  if (percent >= 83 && percent < 87) {
    return LetterGrade.B;
  }
  if (percent >= 80 && percent < 83) {
    return LetterGrade.BMinus;
  }
  if (percent >= 77 && percent < 80) {
    return LetterGrade.CPlus;
  }
  if (percent >= 73 && percent < 77) {
    return LetterGrade.C;
  }
  if (percent >= 70 && percent < 72) {
    return LetterGrade.CMinus;
  }
  if (percent >= 65 && percent < 70) {
    return LetterGrade.D;
  } else {
    return LetterGrade.F;
  }
};
