import { useCallback, RefObject, FC } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

export interface DropZoneProps {
  buttonRef: RefObject<HTMLDivElement>;
  onHandleDropzoneFileUpload(file: File): void;
}

export const DropZone: FC<DropZoneProps> = ({
  buttonRef,
  onHandleDropzoneFileUpload,
  ...rest
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onHandleDropzoneFileUpload(acceptedFiles[0]);
    },
    [onHandleDropzoneFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
  });

  return (
    <div {...getRootProps()} ref={buttonRef} {...rest}>
      <input {...getInputProps()} />
      {isDragActive ? <p>Drop the files here ...</p> : <p>Drag and drop</p>}
    </div>
  );
};

export default styled(DropZone)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 207px;
  width: 275px;
  border: 3px solid #333333;
  border-radius: 15px;
  background-color: rgba(51, 51, 51, 0.1);
  p {
    color: #333333;
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
  }
`;
