import { ParsedCSVRecord, SelectedRows } from "@emberex/csv-import/lib/types";
import Student from "../types/Student";
import { Gender, genderAliases, GENDERS } from "../constants/Gender";
import { Race, RaceAliases, RACES } from "../constants/Race";
import {
  Disability,
  DISABILITIES,
  DisabilityAliases,
} from "../constants/Disability";
import { Grade, GradeAliases, GRADES } from "../constants/Grade";
import { PerformanceGrade } from "../types/PerformanceGrade";
import { LETTER_GRADES } from "../constants/LetterGrade";
import StudentPerformance from "../types/StudentPerformance";

interface ImportError {
  /**
   * The row number of the error
   */
  row: number;

  /**
   * The column/field name that threw the error.
   */
  column: string;

  /**
   * The message of why the row failed to import.
   */
  message: string;
}

interface ImportStudentsResult {
  /**
   * A list of successfully imported students.
   * If there is an error while parsing the record, the student will not be in this list.
   */
  students: Student[];
  /**
   * A list of all the import errors. If a student was successfully imported, their row data
   * will not be in this list.
   */
  errors: ImportError[];
}

function validationError(
  rowIndex: number,
  fieldName: string,
  message = "Invalid Data"
): never {
  throw new Error(`Row: ${rowIndex + 1}, ${fieldName}: ${message}`);
}

/**
 * Check the type of the field of a ParsedCSVRecord, and throw a validation error if not a string.
 */
function assertString(
  field: string,
  csvRow: ParsedCSVRecord,
  rowIndex: number
): string {
  const value = csvRow[field];
  if (typeof value !== "string") {
    validationError(rowIndex, field, `${value} is not a string`);
  }
  return value;
}

/**
 * Check the type of the field of a ParsedCSVRecord, and throw a validation error if not a boolean.
 */
function assertBoolean(
  field: string,
  csvRow: ParsedCSVRecord,
  rowIndex: number
): boolean {
  const value = csvRow[field];
  if (typeof value !== "boolean") {
    validationError(rowIndex, field, `${value} is not a boolean`);
  }
  return value;
}

/**
 * Check if the field is populated in the CSV row, and throw an error if it is not a boolean.
 */
function assertOptionalBoolean(
  field: string,
  csvRow: ParsedCSVRecord,
  rowIndex: number
): boolean | undefined {
  const value = csvRow[field];
  if (typeof value !== "undefined") {
    return assertBoolean(field, csvRow, rowIndex);
  }
  return undefined;
}

/**
 * Check if the field is populated in the CSV row, and throw an error if it is not a string.
 */
function assertOptionalString(
  field: string,
  csvRow: ParsedCSVRecord,
  rowIndex: number
): string | undefined {
  const value = csvRow[field];
  if (typeof value !== "undefined") {
    return assertString(field, csvRow, rowIndex);
  }
  return undefined;
}

function assertArray(
  field: string,
  csvRow: ParsedCSVRecord,
  rowIndex: number
): unknown[] {
  const value = csvRow[field];
  if (Array.isArray(value)) {
    return value;
  }
  throw validationError(rowIndex, field, `Expected a list`);
}

function stringEqualsIgnoreCase(stringA: string, stringB: string): boolean {
  return stringA.toLocaleLowerCase() === stringB.toLocaleLowerCase();
}

function findEnumByStringValue<T>(
  values: T[],
  stringValue: string
): T | undefined {
  return values.find((value) =>
    stringEqualsIgnoreCase(
      // This is *very* internal
      value as unknown as string,
      stringValue
    )
  );
}

function findEnumByAlias<T>(
  aliases: Record<string, string[]>,
  stringValue: string
): T | undefined {
  const value = Object.entries(aliases).find(([, aliasList]) =>
    (aliasList as string[]).some((alias) =>
      stringEqualsIgnoreCase(alias, stringValue)
    )
  );
  // This is *very* internal too
  return value ? (value[0] as unknown as T) : undefined;
}

function assertGender(csvRow: ParsedCSVRecord, rowIndex: number): Gender {
  const stringValue = assertString("gender", csvRow, rowIndex);
  const gender = findEnumByStringValue(GENDERS, stringValue);
  if (gender) {
    return gender;
  }
  const genderByAlias = findEnumByAlias<Gender>(genderAliases, stringValue);

  if (genderByAlias) {
    return genderByAlias;
  }
  validationError(
    rowIndex,
    "gender",
    `${stringValue} is not an expected gender.`
  );
}

function assertRace(csvRow: ParsedCSVRecord, rowIndex: number): Race {
  const stringValue = assertString("race", csvRow, rowIndex);
  const race = findEnumByStringValue(RACES, stringValue);
  if (race) {
    return race;
  }
  const raceByAlias = findEnumByAlias<Race>(RaceAliases, stringValue);
  if (raceByAlias) {
    return raceByAlias;
  }
  validationError(rowIndex, "race", `${stringValue} is not an expected race.`);
}

function assertDisability(
  csvRow: ParsedCSVRecord,
  rowIndex: number
): Disability | null {
  let disabilityList: string[] = [];
  if (!Array.isArray(csvRow["disabilities"])) {
    disabilityList = [assertString("disabilities", csvRow, rowIndex)];
  } else {
    disabilityList = assertArray("disabilities", csvRow, rowIndex) as string[];
  }
  const list = disabilityList.map((value, idx) => {
    if (typeof value !== "string") {
      validationError(rowIndex, `disability[${idx}] is not a string`);
    }
    return value;
  });
  // TODO: make this work with a list...
  const stringValue = list[0];
  if (!stringValue) {
    return null;
  }
  const disability = findEnumByStringValue(DISABILITIES, stringValue);
  if (disability) {
    return disability;
  }
  const disabilityByAlias = findEnumByAlias<Disability>(
    DisabilityAliases,
    stringValue
  );
  return disabilityByAlias ?? null;
}

function assertGrade(csvRow: ParsedCSVRecord, rowIndex: number): Grade {
  const stringValue = assertString("gradeLevel", csvRow, rowIndex);
  const grade = findEnumByStringValue(GRADES, stringValue);
  if (grade) {
    return grade;
  }
  const gradeByAlias = findEnumByAlias<Grade>(GradeAliases, stringValue);
  if (gradeByAlias) {
    return gradeByAlias;
  }

  validationError(
    rowIndex,
    "gradeLevel",
    `${stringValue} is not an expected grade.`
  );
}

function assertPerformanceGrade(
  field: string,
  csvRow: ParsedCSVRecord,
  rowIndex: number,
  required = false
): PerformanceGrade | null {
  const value = required
    ? assertString(field, csvRow, rowIndex)
    : assertOptionalString(field, csvRow, rowIndex);
  if (!value && !required) {
    return null;
  }
  // Shouldn't happen due to assertString
  if (!value && required) {
    throw new Error(
      "Invariant Exception: tried to get a required string but an error was not thrown"
    );
  }
  const stringValue = value as string;

  // Try to parse into a numeric grade.
  const asNumber = +stringValue;
  if (!Number.isNaN(asNumber)) {
    return asNumber;
  }
  const asLetterGrade = findEnumByStringValue(LETTER_GRADES, stringValue);
  if (!asLetterGrade && required) {
    validationError(
      rowIndex,
      field,
      `${stringValue} could not be converted to a numeric or letter grade.`
    );
  }
  return asLetterGrade ?? null;
}

interface SingleRowResult {
  student: Student | null;
  errors: ImportError[];
}

/**
 * Parse a ParsedCSVRecord into a Student.
 * If an error is thrown, a null student is returned, but the error list should contain all the
 * errors in the row (vs. failing at the first error).
 */
function importStudentFromRow(
  csvRow: ParsedCSVRecord,
  rowIndex: number
): SingleRowResult {
  const row = rowIndex + 1;
  const student: Partial<Student> = {};
  const errors: ImportError[] = [];

  function pushError(column: string, error: Error) {
    errors.push({ column, row, message: error.message });
  }

  try {
    student.id = assertString("idNumber", csvRow, rowIndex);
  } catch (e) {
    pushError("idNumber", e as Error);
  }

  try {
    student.firstName = assertString("firstName", csvRow, rowIndex);
  } catch (e) {
    pushError("firstName", e as Error);
  }

  try {
    student.lastName = assertString("lastName", csvRow, rowIndex);
  } catch (e) {
    pushError("lastName", e as Error);
  }

  try {
    student.gender = assertGender(csvRow, rowIndex);
  } catch (e) {
    pushError("gender", e as Error);
  }

  try {
    student.isEll = assertOptionalBoolean("ell", csvRow, rowIndex) ?? false;
  } catch (e) {
    pushError("ell", e as Error);
  }

  try {
    student.has504Plan =
      assertOptionalBoolean("has504plan", csvRow, rowIndex) ?? false;
  } catch (e) {
    pushError("has504Plan", e as Error);
  }

  try {
    student.race = assertRace(csvRow, rowIndex);
  } catch (e) {
    pushError("race", e as Error);
  }

  try {
    student.disability = assertDisability(csvRow, rowIndex);
  } catch (e) {
    pushError("disability", e as Error);
  }

  try {
    student.gradeLevel = assertGrade(csvRow, rowIndex);
  } catch (e) {
    pushError("gradeLevel", e as Error);
  }

  const studentPerformance: Partial<StudentPerformance> = {};

  try {
    studentPerformance.overallGrade =
      assertPerformanceGrade("overallGrade", csvRow, rowIndex, true) ?? 0; // default should not occur
  } catch (e) {
    pushError("overallGrade", e as Error);
  }

  try {
    studentPerformance.writingGrade = assertPerformanceGrade(
      "writingGrade",
      csvRow,
      rowIndex
    );
  } catch (e) {
    pushError("writingGrade", e as Error);
  }

  try {
    studentPerformance.grammarGrade = assertPerformanceGrade(
      "grammarGrade",
      csvRow,
      rowIndex
    );
  } catch (e) {
    pushError("grammarGrade", e as Error);
  }

  try {
    studentPerformance.spellingGrade = assertPerformanceGrade(
      "spellingGrade",
      csvRow,
      rowIndex
    );
  } catch (e) {
    pushError("spellingGrade", e as Error);
  }

  try {
    studentPerformance.historyGrade = assertPerformanceGrade(
      "historyGrade",
      csvRow,
      rowIndex
    );
  } catch (e) {
    pushError("historyGrade", e as Error);
  }

  try {
    studentPerformance.scienceGrade = assertPerformanceGrade(
      "scienceGrade",
      csvRow,
      rowIndex
    );
  } catch (e) {
    pushError("historyGrade", e as Error);
  }

  try {
    studentPerformance.mathGrade = assertPerformanceGrade(
      "mathGrade",
      csvRow,
      rowIndex
    );
  } catch (e) {
    pushError("mathGrade", e as Error);
  }

  return {
    errors,
    // It is safe to assume if no errors thrown, we have a complete student object.
    student: errors.length
      ? null
      : ({ ...student, studentPerformance } as Student),
  };
}

/**
 * Perform the import of students given the importer rows.
 * @param selectedRows the selected CSV data.
 * @return ImportStudentsResult
 */
export default function importStudents({
  data,
}: Pick<SelectedRows, "data">): ImportStudentsResult {
  const results = data.map(importStudentFromRow);
  const students = results
    .map((result) => result.student)
    .filter((student) => student != null) as Student[];

  return {
    students,
    errors: results.flatMap((result) => result.errors),
  };
}
