import { FC } from "react";
import styled from "styled-components";
import { ReactComponent as facebookIcon } from "../images/FbIcon.svg";
import { ReactComponent as linkedInIcon } from "../images/LiIcon.svg";
import { ReactComponent as webIcon } from "../images/WebIcon.svg";

export enum LinkOption {
  EMBEREX = "https://www.emberex.com",
  FACEBOOK = "https://www.facebook.com/Emberex",
  LINKEDIN = "https://www.linkedin.com/company/emberex-inc-",
}

export enum LogoColorOption {
  LIGHT_BLUE = "#5ce5e5",
  PASTEL_PURPLE = "#B61F98",
}

export interface IconLinkProps {
  to: LinkOption;
  linkColor?: LogoColorOption;
}

export const IconLink: FC<IconLinkProps> = ({ to, linkColor, ...rest }) => (
  <a href={to} target="_blank" rel="noreferrer" {...rest}>
    {to === LinkOption.EMBEREX && <WebIcon color={linkColor} />}
    {to === LinkOption.FACEBOOK && <FacebookIcon color={linkColor} />}
    {to === LinkOption.LINKEDIN && <LinkedInIcon color={linkColor} />}
  </a>
);

export default styled(IconLink)``;

const WebIcon = styled(webIcon)<{ color?: LogoColorOption }>`
  fill: ${({ color }) => color ?? undefined};
`;

const FacebookIcon = styled(facebookIcon)<{ color?: LogoColorOption }>`
  fill: ${({ color }) => color ?? undefined};
`;

const LinkedInIcon = styled(linkedInIcon)<{ color?: LogoColorOption }>`
  fill: ${({ color }) => color ?? undefined};
`;
