import { css } from "styled-components/macro";

export default function hideable(props: { hide?: boolean }) {
  const { hide } = props;
  if (hide) {
    return css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `;
  }
  return css`
    opacity: 1;
  `;
}

interface HideProps {
  hide: boolean;
  "aria-hidden": boolean;
  tabIndex: number;
}

/**
 * Use in conjunction with hidable to visual hide a component as well as
 * remove it from tab order and indicate that its hidden with screen readers
 * @param hide
 */
export function ariaHideable(hide: boolean): HideProps {
  return {
    hide,
    "aria-hidden": hide,
    tabIndex: hide ? -1 : 0,
  };
}
