import { FC, useCallback, useMemo, MouseEvent } from "react";
import styled, { css, keyframes } from "styled-components";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import useSort from "@emberex/react-utils/lib/useSort";

export interface BarChartData {
  label: string;
  value: number;
  color: string;
}

type SortDirection = "asc" | "desc";

export type BarClickCallback = (
  element: HTMLDivElement,
  dataIndex: number
) => void;

export interface BarChartProps {
  data: BarChartData[];
  sortDirection?: SortDirection;
  onBarClick: BarClickCallback;
}

function compare(
  dataA: BarChartData,
  dataB: BarChartData,
  sortDirection?: SortDirection
) {
  if (sortDirection) {
    if (sortDirection === "asc") {
      return dataA.value - dataB.value;
    }
    return dataB.value - dataA.value;
  }
  return 0;
}

export const BarChart: FC<BarChartProps> = ({
  data,
  sortDirection,
  onBarClick,
  ...rest
}) => {
  const compareData = useCallback(
    (dataA: BarChartData, dataB: BarChartData) =>
      compare(dataA, dataB, sortDirection),
    [sortDirection]
  );

  const maxValue = useMemo(
    () => Math.max(1, Math.max(...data.map((d) => d.value))),
    [data]
  );

  const getWidthRatio = useCallback(
    (value: number) => (value / maxValue) * 100,
    [maxValue]
  );

  const sortedData = useSort(data, compareData);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>, idx: number) => {
      onBarClick(event.currentTarget, idx);
    },
    [onBarClick]
  );

  return (
    <Root {...rest}>
      {sortedData.map(({ value, label, color }, idx) => {
        const widthRatio = getWidthRatio(value);
        return (
          <BarData key={label}>
            <BarLabel>{label}</BarLabel>
            <BarContainer>
              <Bar
                color={color}
                widthRatio={widthRatio}
                animationOffset={idx}
                onClick={(e) => handleClick(e, idx)}
              >
                {widthRatio >= 1 && <BarCount>{value}</BarCount>}
              </Bar>
              {/* Move the count outside of the bar and make it white for low ratios */}
              {widthRatio < 1 && <BarCount white>{value}</BarCount>}
            </BarContainer>
          </BarData>
        );
      })}
    </Root>
  );
};

export default styled(BarChart)``;

const BarData = styled(Row)`
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
`;

const Root = styled(Column)`
  ${BarData} + ${BarData} {
    margin-top: 1.5rem;
  }
  width: 100%;
`;

const BarLabel = styled(Row)`
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  text-align: right;
  flex: 1 0 auto;
  width: 33%;
  max-width: 231px;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 1.5rem;
`;

const BarContainer = styled(Row)`
  height: 60px;
  width: 100%;
`;

const animateWidth = (widthPercent: number) => keyframes`
  from {
    width: 0;
  }
  to {
    width: ${widthPercent}%;
  }
`;

const BarCount = styled(Row)<{ white?: boolean }>`
  color: ${(props) => (props.white ? "#FFFFFF" : "#000000")};
  font-family: "Muli";
  font-weight: 300;
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 2.5rem;
  text-align: center;
  height: 100%;
  align-items: center;
`;

const Bar = styled(Row)<{
  color: string;
  widthRatio: number;
  animationOffset: number;
}>`
  width: 0;
  transition: width 1s;
  background-color: ${(props) => props.color};
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.widthRatio &&
    css`
      animation: ${animateWidth(props.widthRatio)} 1s both;
    `}
  margin-right: 0.3125rem;
  // Delay by n * 0.2 seconds
  animation-delay: ${(props) => props.animationOffset * 0.2}s;
`;
