import { FC } from "react";
import styled from "styled-components";
import Column from "@emberex/components/lib/Column";
import noop from "../utils/noop";
import AnimatedIsland from "./AnimatedIsland";
import IslandLink from "./IslandLink";

export interface MobileImportDashboardIslandsProps {
  onImportLinkClick?(): unknown;
  onDashboardLinkClick?(): unknown;
}

export const MobileImportDashboardIslands: FC<
  MobileImportDashboardIslandsProps
> = ({ onImportLinkClick = noop, onDashboardLinkClick = noop, ...rest }) => {
  return (
    <Column {...rest}>
      <AnimatedIsland islandOption="import">
        <ImportLink option="import" onClick={onImportLinkClick} />
      </AnimatedIsland>
      <AnimatedIsland islandOption="dashboard">
        <DashLink option="dashboard" onClick={onDashboardLinkClick} />
      </AnimatedIsland>
    </Column>
  );
};

export default styled(MobileImportDashboardIslands)`
  ${AnimatedIsland} {
    width: 100%;
  }
  ${AnimatedIsland} + ${AnimatedIsland} {
    margin-top: -40px;
  }
`;

const ImportLink = styled(IslandLink)`
  position: absolute;
  width: 35%;
  right: 13%;
  bottom: 37%;
`;

const DashLink = styled(IslandLink)`
  position: absolute;
  width: 55%;
  right: 27%;
  bottom: 45%;
`;
