import { useRef, useCallback, FC } from "react";
import styled, { css } from "styled-components";
import DropZone from "./Dropzone";
import TextButton from "@emberex/components/lib/TextButton";
import downloadLogoSrc from "../images/download.png";
import downloadSample from "../utils/downloadSample";

export interface UploadFormProps {
  onHandleFileUpload(file: File): void;
  uploadEnabled: boolean;
  hideDropZone?: boolean;
}

const UploadForm: FC<UploadFormProps> = ({
  onHandleFileUpload,
  uploadEnabled,
  hideDropZone,
}) => {
  const dropZoneRef = useRef<HTMLDivElement>(null);

  const handleDropzoneFileUpload = useCallback(
    (file: File) => {
      if (file) {
        onHandleFileUpload(file);
      }
    },
    [onHandleFileUpload]
  );

  const handleFileUploadOnClick = useCallback(() => {
    dropZoneRef.current?.click();
  }, [dropZoneRef]);

  return (
    <Root uploadEnabled={uploadEnabled} hideDropZone={hideDropZone}>
      <Header>Select a file</Header>
      <ButtonGroup>
        <UploadButton
          uploadEnabled={uploadEnabled}
          onClick={handleFileUploadOnClick}
          disabled={!uploadEnabled}
        >
          <ButtonLabel>Browse</ButtonLabel>
        </UploadButton>
        <DownloadContainer>
          <DownloadLogo className="tooltip">
            <img src={downloadLogoSrc} alt="Download sample csv" />
            <span className="tooltiptext">Download Sample CSV</span>
          </DownloadLogo>
        </DownloadContainer>
      </ButtonGroup>
      <DropZone
        buttonRef={dropZoneRef}
        onHandleDropzoneFileUpload={handleDropzoneFileUpload}
      />
    </Root>
  );
};

export default UploadForm;

const Root = styled("div")<{ uploadEnabled: boolean; hideDropZone?: boolean }>`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  pointer-events: ${(props) => (props.uploadEnabled ? "all" : "none")};

  ${DropZone} {
    transition: all 0.5s ease;

    ${({ hideDropZone }) =>
      hideDropZone &&
      css`
        opacity: 0;
        pointer-events: none;
      `}
  }
`;

const Header = styled("div")`
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
`;

const ButtonLabel = styled("div")`
  font-weight: 300;
  font-size: 18px;
`;

const ButtonGroup = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const UploadButton = styled(TextButton)<{ uploadEnabled: boolean }>`
  width: 120px;
  height: 48px;
  border-radius: 43px;
  background-color: ${(props) => (props.uploadEnabled ? "#333333" : "#b7b7b7")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 25px;
  ${ButtonLabel} {
    color: ${(props) => (props.uploadEnabled ? "#adf1e8" : " #333333")};
  }
`;

const DownloadContainer = styled("div")`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  pointer-events: all;
`;

const DownloadLogo = styled.a.attrs({
  href: downloadSample(),
  download: "students.csv",
  target: "_blank",
})`
  img {
    width: 50px;
    height: 50px;
  }
`;
