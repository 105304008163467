import { Grade } from "../../constants/Grade";
import StudentPerformance from "../../types/StudentPerformance";
import Student from "../../types/Student";
import { useMemo } from "react";

function getStudentPerformanceForGrade(
  students: ReadonlyArray<Pick<Student, "gradeLevel" | "studentPerformance">>,
  grade: Grade
): StudentPerformance[] {
  return students
    .filter((student) => student.gradeLevel === grade)
    .flatMap((student) => student.studentPerformance);
}

export default function useSubjectByGradeChart(
  students: ReadonlyArray<Pick<Student, "gradeLevel" | "studentPerformance">>
): Record<Grade, StudentPerformance[]> {
  return useMemo((): Record<Grade, StudentPerformance[]> => {
    return {
      [Grade.KINDERGARTEN]: getStudentPerformanceForGrade(
        students,
        Grade.KINDERGARTEN
      ),
      [Grade.FIRST]: getStudentPerformanceForGrade(students, Grade.FIRST),
      [Grade.SECOND]: getStudentPerformanceForGrade(students, Grade.SECOND),
      [Grade.THIRD]: getStudentPerformanceForGrade(students, Grade.THIRD),
      [Grade.FOURTH]: getStudentPerformanceForGrade(students, Grade.FOURTH),
      [Grade.FIFTH]: getStudentPerformanceForGrade(students, Grade.FIFTH),
      [Grade.SIXTH]: getStudentPerformanceForGrade(students, Grade.SIXTH),
    };
  }, [students]);
}
