import { FC, useCallback, useMemo, useState, useRef } from "react";
import styled from "styled-components";
import DashboardPane from "./DashboardPane";
import { Disability } from "../constants/Disability";
import Student from "../types/Student";
import useSort from "@emberex/react-utils/lib/useSort";
import BarChart, { BarChartData, BarClickCallback } from "./BarChart";
import ChartTitle from "./ChartTitle";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import { getVirtualElementInScrollingContainer } from "../utils/popoverAnchorVirtualElement";
import StudentListPopover, { AnchorElement } from "./StudentListPopover";
import { useOnOutOfBounds } from "../hooks/useOnOutOfBounds";

const colors = [
  "#d3ef58",
  "#abeb78",
  "#91e98d",
  "#5ce5b8",
  "#5ce5d0",
  "#5ccfe5",
  "#5cb8e5",
  "#738ae5",
  "#8a5ce5",
  "#a15ce5",
  "#b85ce5",
  "#b85ce5",
];

export interface DisabilityData {
  disability: Disability;
  students: readonly Student[];
}

export interface DisabilitiesChartProps {
  data: DisabilityData[];
}

function countDescending(dataA: DisabilityData, dataB: DisabilityData): number {
  return dataB.students.length - dataA.students.length;
}

type PopoverData = null | {
  anchor: AnchorElement;
  label: string;
  students: readonly Student[];
};

export const DisabilitiesChart: FC<DisabilitiesChartProps> = ({
  data,
  ...rest
}) => {
  const sortedData = useSort(data, countDescending);
  const mappedData = useMemo(
    (): BarChartData[] =>
      sortedData
        .filter((current) => current.students.length > 0)
        .map(
          (current, i): BarChartData => ({
            value: current.students.length,
            color: colors[i % colors.length],
            label: current.disability,
          })
        ),
    [sortedData]
  );

  const totalCount = useMemo(
    () => data.reduce((count, current) => count + current.students.length, 0),
    [data]
  );

  const [popoverData, setPopoverData] = useState<PopoverData>(null);
  const handleClosePopover = useCallback(() => setPopoverData(null), []);

  const chartsContainer = useRef<HTMLDivElement>(null);
  const overflowContainer = useRef<HTMLDivElement>(null);

  const handleChartClick: BarClickCallback = (element, dataIndex) => {
    const { disability, students } = sortedData[dataIndex];

    setPopoverData({
      label: disability,
      students,
      anchor: getVirtualElementInScrollingContainer({
        target: element,
        container: chartsContainer.current!, // Not null if we got a click
        containerPadding: 10,
      }),
    });
  };

  useOnOutOfBounds(
    overflowContainer,
    !!popoverData,
    handleClosePopover,
    popoverData?.anchor as Element
  );

  return (
    <DashboardPane ref={chartsContainer} {...rest}>
      <ChartTitleContainer>
        <ChartTitle>Disabilities</ChartTitle>
        <ChartCount>
          <span>{totalCount}</span>
          <span>Total Students with Learning Disabilities</span>
        </ChartCount>
      </ChartTitleContainer>
      <InnerPanel ref={overflowContainer}>
        <BarChart data={mappedData} onBarClick={handleChartClick} />
      </InnerPanel>
      {popoverData && (
        <StudentListPopover
          chartTitle="Disabilities"
          chartDataName={popoverData.label}
          students={popoverData.students}
          anchorElement={popoverData.anchor}
          onClose={handleClosePopover}
        />
      )}
    </DashboardPane>
  );
};

export default styled(DisabilitiesChart)`
  flex-direction: column;
  height: 540px;
  padding: 1.5rem 0.625rem 0.75rem 1.5rem;
  width: 100%;

  ${StudentListPopover} {
    z-index: 1;
  }
`;

const InnerPanel = styled(Column)`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

const ChartTitleContainer = styled(Column)`
  width: 100%;
`;

const ChartCount = styled(Row)`
  align-items: baseline;
  color: #ffffff;
  > :first-child {
    font-family: "Muli";
    font-weight: 300;
    font-size: 2.875rem;
    letter-spacing: 0;
    line-height: 3.625rem;
    margin-right: 0.3125rem;
  }
  > :last-child {
    letter-spacing: 0;
    line-height: 1.25rem;
  }
  margin-bottom: 2rem;
`;
