import { FC } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import Row from "@emberex/components/lib/Row";
import HamburgerButton from "./HamburgerButton";
import studentIcon from "../images/student_icon.png";
import {
  HOME,
  IMPORT_ISLAND,
  DASHBOARD_DISTRICT,
  IMPORT_ISLAND_UPLOAD_COMPLETE,
  BaseRoute,
} from "../constants/routes";
import { useStudents } from "../context/studentContext";
import GradeDropdown from "./GradeDropdown";
import { useGrades } from "../context/gradeContext";
import { BreakPoint } from "../constants/BreakPoint";
import TitleLink from "./TitleLink";
import { useMenu } from "../context/menuContext";

function matchBaseRoute(path: string): BaseRoute {
  // check for match of base route based on sub routes being added when going through import stages
  // /import-island/ => import-island/import => import-island/import-complete
  if (path.includes(IMPORT_ISLAND)) {
    return IMPORT_ISLAND;
  }
  if (path === DASHBOARD_DISTRICT) {
    return DASHBOARD_DISTRICT;
  }
  return HOME;
}

export const Header: FC = (props) => {
  const { students } = useStudents();
  const { globalSelectedGrade, updateGlobalSelectedGrade } = useGrades();
  const { menuOpen, toggleMenu } = useMenu();
  const { pathname } = useLocation();

  const isDashboardDistrict = pathname === DASHBOARD_DISTRICT;
  const hideTitle = pathname === IMPORT_ISLAND_UPLOAD_COMPLETE;
  const linkOption = matchBaseRoute(pathname);

  return (
    <Root route={linkOption} {...props}>
      {!hideTitle && <TitleLink to={HOME} option={linkOption} />}
      <BurgerButton menuOpen={menuOpen} onClick={toggleMenu} />
      {isDashboardDistrict && (
        <StudentCount>
          {students.length}
          <img src={studentIcon} alt="student count" />
          <GradeDropdown
            selected={globalSelectedGrade}
            onSelected={updateGlobalSelectedGrade}
          />
        </StudentCount>
      )}
    </Root>
  );
};

export default styled(Header)``;

const BurgerButton = styled(HamburgerButton)`
  position: absolute;
  top: 39px;
  right: 58px;
  transition: all 0.8s ease;
  z-index: 102;
`;

const StudentCount = styled(Row)`
  align-items: center;
  color: #262626;
  font-family: "Muli";
  font-weight: 600;
  font-size: 2.875rem;
  letter-spacing: 0;
  margin-left: 48px;

  img {
    height: 34px;
    width: 30px;
    margin: 0 30px 0 16px;
  }
`;

const Root = styled(Row)<{ route: BaseRoute }>`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  justify-content: ${({ route }) =>
    route === IMPORT_ISLAND ? "flex-start" : "center"};

  padding: ${({ route }) =>
    route === HOME
      ? "22px 0 0 0"
      : route === IMPORT_ISLAND
      ? "63px 0 0 84px;"
      : "63px 24px 0 24px"};

  @media ${BreakPoint.XL} {
    h1 {
      font-size: ${({ route }) => route === DASHBOARD_DISTRICT && "50px"};
    }
  }

  @media ${BreakPoint.LARGE} {
    padding: ${({ route }) =>
      route === HOME ? "39px 0 0 0" : "60px 0 0 60px;"};

    h1 {
      font-size: ${({ route }) => (route === HOME ? "32px" : "40px")};
    }
    ${BurgerButton} {
      right: 26px;
    }
  }

  @media ${BreakPoint.MEDIUM} {
    padding-left: ${({ route }) => route === IMPORT_ISLAND && "20px"};

    ${BurgerButton} {
      right: 20px;
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 28px;
    }
  }

  @media (max-width: 450px) {
    justify-content: flex-start;
    ${({ route }) =>
      route === HOME
        ? css`
            padding: 48px 0 0 12px;
            h1,
            a {
              font-size: 24px;
              width: 146px;
            }
          `
        : css`
            padding: 57px 0 0 12px;
          `}

    ${BurgerButton} {
      top: 64px;
    }
  }
`;
