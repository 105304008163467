import styled from "styled-components";

export default styled.div`
  color: #d3ef58;
  font-family: "Muli";
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.25rem;
`;
