import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Link, To } from "react-router-dom";
import {
  HOME,
  IMPORT_ISLAND,
  DASHBOARD_DISTRICT,
  BaseRoute,
} from "../constants/routes";
import noop from "../utils/noop";

export interface TitleLinkProps {
  to: To;
  option: BaseRoute;
  onLinkClick?: () => void;
}

const TitleLink: FC<TitleLinkProps> = ({
  option,
  onLinkClick = noop,
  ...props
}) => (
  <Link {...props} onClick={onLinkClick}>
    {titleOptions[option]}
  </Link>
);

export default styled(TitleLink)`
  text-decoration: none;
  h1 {
    display: inline;
  }
`;

const HomeTitle = styled.h1`
  color: white;
  font-family: "Rubik";
  font-size: 60px;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.8s ease;
`;

const IslandTitle = styled.h1`
  font-size: 70px;
  font-family: "Train One";
  text-transform: uppercase;
  text-shadow: 5px 5px #000;
  color: #d3ef58;
  font-weight: 400;
  margin: 0;
  transition: all 0.8s ease;
`;

const DashIslandTitle = styled(IslandTitle)``;

const Highlight = styled.span`
  color: #adf1e8;
`;

const titleOptions: Record<BaseRoute, ReactNode> = {
  [HOME]: (
    <HomeTitle>
      Emberex <Highlight>Ed</Highlight>venture
    </HomeTitle>
  ),
  [DASHBOARD_DISTRICT]: <DashIslandTitle>Dashboard District</DashIslandTitle>,
  [IMPORT_ISLAND]: <IslandTitle>Import Island</IslandTitle>,
};
