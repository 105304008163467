export enum Disability {
  AUTISM = "Autism",
  INTELLECTUAL_DISABILITY = "Intellectual Disability",
  SPEECH_IMPAIRMENT = "Speech / Language Impairment",
  DEAF_BLIND = "Deaf-blindness",
  MULTIPLE = "Multiple Disability",
  BRAIN_TRAUMA = "Traumatic Brain Injury",
  EMOTIONAL_DISTURBANCE = "Emotional Disturbance",
  ORTHOPEDIC_IMPAIRMENT = "Orthopedic Impairment",
  VISUAL_IMPAIRMENT = "Visual Impairment",
  HEARING_IMPAIRMENT = "Hearing Impairment",
  SPECIFIC_LEARNING_DISABILITY = "Specific Learning Disability",
  OTHER = "Other Health Impairment",
}

export const DISABILITIES = Object.values(Disability);

export const DisabilityAliases: Record<Disability, string[]> = {
  [Disability.AUTISM]: ["AU"],
  [Disability.INTELLECTUAL_DISABILITY]: ["ID"],
  [Disability.SPEECH_IMPAIRMENT]: ["SLI"],
  [Disability.DEAF_BLIND]: ["DB"],
  [Disability.MULTIPLE]: ["MD"],
  [Disability.BRAIN_TRAUMA]: ["TBI"],
  [Disability.EMOTIONAL_DISTURBANCE]: ["ED"],
  [Disability.ORTHOPEDIC_IMPAIRMENT]: ["OI"],
  [Disability.VISUAL_IMPAIRMENT]: ["VI"],
  [Disability.HEARING_IMPAIRMENT]: ["HI"],
  [Disability.SPECIFIC_LEARNING_DISABILITY]: ["SLD"],
  [Disability.OTHER]: ["OHI"],
};

export type DisabilityDistribution = Record<Disability, number>;

//  based off stat source below
export const studentsWithDisabilityPercentage = 15;

// based off stats provided in resource => All Available findings (table data)
// https://nces.ed.gov/programs/coe/indicator/cgg/students-with-disabilities
export const defaultDisabalityDistribution: DisabilityDistribution = {
  [Disability.AUTISM]: 12,
  [Disability.INTELLECTUAL_DISABILITY]: 6,
  [Disability.SPEECH_IMPAIRMENT]: 19,
  [Disability.DEAF_BLIND]: 0,
  [Disability.MULTIPLE]: 2,
  [Disability.BRAIN_TRAUMA]: 0,
  [Disability.EMOTIONAL_DISTURBANCE]: 5,
  [Disability.ORTHOPEDIC_IMPAIRMENT]: 0,
  [Disability.VISUAL_IMPAIRMENT]: 0,
  [Disability.HEARING_IMPAIRMENT]: 1,
  [Disability.SPECIFIC_LEARNING_DISABILITY]: 33,
  [Disability.OTHER]: 15,
};
