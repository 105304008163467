import Student from "../../types/Student";
import { useMemo } from "react";
import { Grade, GRADES } from "../../constants/Grade";
import getGradeCount from "../../utils/getGradeCount";

type GradeTotals = Record<Grade, number>;

export default function useStudentGradeTotalsChart(
  students: ReadonlyArray<Pick<Student, "gradeLevel">>
): GradeTotals {
  return useMemo(
    (): GradeTotals =>
      GRADES.reduce<GradeTotals>((totals, grade) => {
        totals[grade] = getGradeCount(grade, students);
        return totals;
      }, {} as GradeTotals),
    [students]
  );
}
