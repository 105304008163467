import { FC } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import { HOME, IMPORT_ISLAND, DASHBOARD_DISTRICT } from "./constants/routes";
import { Home, IslandSelect, ImportIsland, DashboardDistrict } from "./pages";

const App: FC = () => (
  <RouterProvider
    router={createBrowserRouter(
      createRoutesFromElements(
        <>
          <Route path={HOME} element={<Home />}>
            <Route path={HOME} element={<IslandSelect />} />
            <Route path={`${IMPORT_ISLAND}/*`} element={<ImportIsland />} />
            <Route path={DASHBOARD_DISTRICT} element={<DashboardDistrict />} />
          </Route>
          <Route path="*" element={<Navigate to={HOME} replace={true} />} />
        </>
      )
    )}
  />
);

export default App;
