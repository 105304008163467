import Student from "../../types/Student";
import { DisabilityData } from "../../components/DisabilitiesChart";
import { useMemo } from "react";
import { DISABILITIES } from "../../constants/Disability";

export default function useDisabilitiesChart(
  students: readonly Student[]
): DisabilityData[] {
  return useMemo((): DisabilityData[] => {
    return DISABILITIES.map((disability) => ({
      disability,
      students: students.filter((student) => student.disability === disability),
    }));
  }, [students]);
}
